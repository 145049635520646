import { formSelectOptions } from '../../fieldsPatientsData/formSelectOptions';
import { PatientInputType } from "../../fieldsPatientsData/patientInputType";
import React, { useContext, useState, useEffect } from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, Formik } from "formik";
import { Modal, Form, Button, Container, Row, Accordion, Col } from "react-bootstrap";
import Select from "react-select";
import AuthContext from "../../../../../auth/authContext";
import { format } from 'date-fns';
import { PatientIC2InputType } from "../../fieldsPatientsData/patientIC2InputType";


const TabIC1 = ({ patientID }) => {
    const base_url = process.env.REACT_APP_BASE_URL;
    const [errorShow, setErrorShow] = useState(false);
    const [patientIRC, setPatientIRC] = useState(false);
    const [dataLoading, setDataLoading] = useState(true);
    const [show, setShow] = useState(false);
    const { refresh, setIsAuthenticated, redirectTo, setRedirectTo } = useContext(AuthContext);
    const [isLoggedOut, setIsLoggedOut] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [editData, setEditData] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleLogout = () => {
        setIsAuthenticated(false);
        setRedirectTo('/login');
        localStorage.removeItem('currentUserData');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        setIsLoggedOut(true);
    };
    const currentUserData = JSON.parse(localStorage.getItem('currentUserData') || '{}');

    const translateValue = (field, value) => {
        const fieldTranslations = formSelectOptions[field];
        if (!fieldTranslations) return value;
        const foundTranslation = fieldTranslations.find((item) => item.label === value);
        return foundTranslation ? foundTranslation.translatedLabel : value;
    };
    const translateArrayValues = (field, values) => {
        return values.map((value) => translateValue(field, value));
    };
    const generateValidationSchema = (fields) => {
        const schemaFields = {};
        fields.map((item) => {
            switch (item.type) {
                case 'number':
                    schemaFields[item.label] = Yup.number()
                        .typeError(`${item.name} должно быть числом`)
                        .required(`Поле ${item.name} является обязательным`);
                    break;
                case 'input':
                    schemaFields[item.label] = Yup.string()
                        .required(`Поле ${item.name} является обязательным`);
                    break;
                case 'select':
                    schemaFields[item.label] = Yup.string()
                        .required(`Выберите однo из значений`);
                    break;
                case 'multiselect':
                    schemaFields[item.label] = Yup.array()
                        .of(Yup.string())
                        .min(1, `Выберите хотя бы одно значение из списка`)
                        .required(`Выберите хотя бы одно значение из списка`);
                    break;
                case 'boolean':
                    schemaFields[item.label] = Yup.boolean()
                        .required(`${item.name} является обязательным`);
                    break;
                default:
                    schemaFields[item.label] = Yup.string();
            }
        });

        return Yup.object().shape(schemaFields);
    };

    const validationSchema = generateValidationSchema(PatientInputType);
    const fetchPatientIRCData = async () => {
        const currentUserData = localStorage.getItem('currentUserData');
        let accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');

        if (!currentUserData) {
            handleLogout();
            return;
        }

        if (!accessToken && refreshToken) {
            try {
                await refresh();
                accessToken = localStorage.getItem('accessToken');
            } catch (error) {
                handleLogout();
                return;
            }
        }

        try {
            let response = await fetch(base_url + `/individual-registration-card-part1/patient/${patientID}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                }
            });

            if (response.status === 401) {
                try {
                    await refresh();
                    accessToken = localStorage.getItem('accessToken');
                    response = await fetch(base_url + `/individual-registration-card-part1/patient/${patientID}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + accessToken
                        }
                    });
                } catch (error) {
                    handleLogout();
                    return;
                }
            }

            const patientIRC = await response.json();
            setPatientIRC(patientIRC);
            setDataLoading(false);
        } catch (error) {
            console.log("An error occurred while trying to fetch the patient's data.");
            setDataLoading(false);
        }
    };
    useEffect(() => {
        if (patientID) {
            fetchPatientIRCData();
        }

    }, [patientID]);


    const initialValues = PatientInputType.reduce((values, item) => {
        switch (item.type) {
            case 'select':
            case 'number':
                values[item.label] = ''; // Initialize select and number fields with an empty string
                break;
            case 'multiselect':
                values[item.label] = []; // Initialize multiselect fields with an empty array
                break;
            case 'boolean':
                values[item.label] = false; // Initialize boolean fields with false
                break;
            default:
                values[item.label] = ''; // Default to empty string for any other type
        }
        return values;
    }, {});

    const headersMapping = {
        created_at:"",
        ethnicity: "Этническая принадлежность",
        region: "Регион (Казахстан)",
        height: "Рост, см",
        education_level: "Уровень образования",
        marital_status: "Семейное положение",
        job_type: "Тип работы",
        drives_vehicle: "Водит транспортное средство",
        involved_in_car_accident: "Был ли вовлечен в автомобильную аварию",
        liver_conditions: "Состояния печени",
        liver_disease_duration_years: "Длительность заболевания печени (в годах)",
        blood_ammonia_test_result: "Результат теста на аммиак в крови",
        liver_elastography_result: "Результат эластографии печени",
        spleen_elastography_result: "Результат эластографии селезенки",
        varices_degree: "Степень варикозного расширения",
        red_signs_varices: "Наличие красных знаков на варикозных венах",
        ascites_status: "Статус асцита",
        comorbidities: "Сопутствующие заболевания",
    };
    const asd = {
        created_at:"asd"
    }

    const handleSubmit = (values) => {
        values['patient_id'] = patientID;
        console.log('Form Data:', values);
        sendFormData(values);
    }
    if (dataLoading) {
        return <div className='loader'></div>;
    }
    const renderFormField = (item, formik) => {
        const { values, setFieldValue } = formik;

        const customStyles = {
            control: (provided, state) => ({
                ...provided,
                borderColor: formik.touched[item.label] && formik.errors[item.label] ? 'red' : '#ced4da',
                boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : 'none',
                '&:hover': {
                    borderColor: formik.touched[item.label] && formik.errors[item.label] ? 'red' : '#80bdff',
                },
                minHeight: 'calc(1.5em + 0.75rem + 2px)', // Matches Bootstrap input height
            }),
            menu: (provided) => ({
                ...provided,
                zIndex: 9999, // Make sure the dropdown appears above other elements
            }),
        };

        switch (item.type) {
            case 'select':
                return (
                    <div key={item.label} className="form-group mb-2">
                        <label>{item.name}</label>
                        <Select
                            options={
                                formSelectOptions[item.label]?.map((option) => ({
                                    value: option.label,
                                    label: option.translatedLabel,
                                })) || []
                            }
                            value={
                                formSelectOptions[item.label]?.find(
                                    (option) => option.label === values[item.label]
                                ) && {
                                    value: values[item.label],
                                    label: formSelectOptions[item.label].find(
                                        (option) => option.label === values[item.label]
                                    )?.translatedLabel,
                                }
                            }
                            onChange={(option) =>
                                setFieldValue(item.label, option ? option.value : '')
                            }
                            styles={customStyles}
                            className={`react-select-container ${formik.touched[item.label] && formik.errors[item.label] ? 'is-invalid' : ''
                                }`}
                            placeholder="Выберите один из вариантов"
                        />
                        <ErrorMessage
                            name={item.label}
                            component="div"
                            className="text-danger"
                        />
                    </div>
                );
            case 'multiselect':
                return (
                    <div key={item.label} className="form-group mb-2">
                        <label>{item.name}</label>
                        <Select
                            isMulti
                            options={
                                formSelectOptions[item.label]?.map((option) => ({
                                    value: option.label,
                                    label: option.translatedLabel,
                                })) || []
                            }
                            value={
                                values[item.label]?.map((value) => {
                                    const matchedOption = formSelectOptions[item.label]?.find(
                                        (option) => option.label === value
                                    );
                                    return (
                                        matchedOption && {
                                            value: matchedOption.label,
                                            label: matchedOption.translatedLabel,
                                        }
                                    );
                                }) || []
                            }
                            onChange={(selectedOptions) => {
                                const selectedValues = selectedOptions
                                    ? selectedOptions.map((opt) => opt.value)
                                    : [];
                                setFieldValue(item.label, selectedValues);
                            }}
                            styles={customStyles}
                            className={`react-select-container ${formik.touched[item.label] && formik.errors[item.label] ? 'is-invalid' : ''
                                }`}
                            placeholder="Выберите один или несколько вариантов"
                        />
                        <ErrorMessage
                            name={item.label}
                            component="div"
                            className="text-danger"
                        />
                    </div>
                );
            case 'number':
                return (
                    <div key={item.label} className="form-group mb-2">
                        <label>{item.name}</label>
                        <Field
                            type="number"
                            name={item.label}
                            className={`form-control ${formik.touched[item.label] && formik.errors[item.label] ? 'is-invalid' : ''}`}
                            placeholder="Введите число"
                            onWheel={(e) => e.target.blur()} // Prevent number change on wheel scroll
                        />
                        <ErrorMessage name={item.label} component="div" className="text-danger" />
                    </div>
                );
            case 'input':
                return (
                    <div key={item.label} className="form-group mb-2">
                        <label>{item.name}</label>
                        <Field
                            type="text"
                            name={item.label}
                            className={`form-control ${formik.touched[item.label] && formik.errors[item.label] ? 'is-invalid' : ''}`}
                            placeholder="Введите значение"
                        />
                        <ErrorMessage name={item.label} component="div" className="text-danger" />
                    </div>
                );
            case 'boolean':
                return (
                    <div key={item.label} className="form-group form-check mb-2">
                        <Field
                            type="checkbox"
                            name={item.label}
                            className="form-check-input"
                            id={item.label}
                        />
                        <label className="form-check-label" htmlFor={item.label}>
                            {item.name}
                        </label>
                        <ErrorMessage name={item.label} component="div" className="text-danger" />
                    </div>
                );
            default:
                return null;
        }
    };
    const sendFormData = async (data) => {
        console.log('Sending form data...');
        const currentUserData = localStorage.getItem('currentUserData');
        let accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            const response = await fetch(base_url + '/individual-registration-card-part1/', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + accessToken,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                if (response.status === 400) {
                    const errorData = await response.json();
                    throw new Error(`Bad Request: ${errorData.message}`);
                } else {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            }

            const result = await response.json();
            console.log('Form submitted successfully:', result);
            handleClose();
            fetchPatientIRCData();

        } catch (error) {
            console.error('Error sending form:', error);
            setErrorShow(true);
        }
    }
    if (dataLoading) {
        return <div className='loader'></div>;
    }
    const handleEdit = (data) => {
        console.log(`Edit button clicked for patient with ID: ${data.id}`);
        // Add your edit logic here
    };
    const openEditModal = (data) => {
        setEditData(data);
        setShowEditModal(true);
    };
    const handleEditSubmit = async (values) => {
        console.log('Form submitted with values:', values);
        const currentUserData = localStorage.getItem('currentUserData');
        let accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            const response = await fetch(base_url + `/individual-registration-card-part1/${values.id}`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + accessToken,
                },
                body: JSON.stringify(values),
            });
            if (response.ok) {
                console.log('Patient data updated successfully');
                // Add logic to update the UI after successful update
            } else {
                console.error('Failed to update patient data');
            }
        } catch (error) {
            console.error('An error occurred while updating patient data:', error);
        }
        setShowEditModal(false);
        fetchPatientIRCData()
    };
    const EditModal = ({ show, onHide, data, onSubmit }) => (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Редактировать ИРК1</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={data || initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    onSubmit(values);
                    setSubmitting(false);
                }}
            >
                {(formik) => (
                    <Form noValidate onSubmit={formik.handleSubmit}>
                        <Modal.Body>
                            {PatientInputType.map((item) => renderFormField(item, formik))}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={onHide}>Закрыть</Button>
                            <Button variant="primary" type="submit" className="btn btn-primary">Отправить</Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );


    const confirmDelete = async () => {
        console.log(`Confirm delete for patient with ID: ${deleteId}`);
        const currentUserData = localStorage.getItem('currentUserData');
        let accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            const response = await fetch(base_url + `/individual-registration-card-part1/${deleteId}/`, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + accessToken,
                },
            });
            if (response.ok) {
                console.log('Patient data deleted successfully');
                // Add logic to update the UI after successful deletion
            } else {
                console.error('Failed to delete patient data');
            }
        } catch (error) {
            console.error('An error occurred while deleting patient data:', error);
        }
        setShowDeleteModal(false);
        fetchPatientIRCData();
    };

    const openDeleteModal = (id) => {
        setDeleteId(id);
        setShowDeleteModal(true);
    };

    const DeleteModal = ({ show, onHide, confirmDelete }) => (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} size="sm" centered>
            <Modal.Header closeButton>
                <Modal.Title>Удалить запись ИРК1</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Вы уверены что хотите удалить запись ИРК1?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Отменить</Button>
                <Button variant="danger" onClick={confirmDelete}>Удалить</Button>
            </Modal.Footer>
        </Modal>
    );
    
    return (
        <Container className="p-4  rounded-3 border shadow-lg">
            <div className='d-flex mb-3  justify-content-between align-content-center'>
                <h4 className=' mt-2'>Список ИРК-1</h4>
                {currentUserData.role !== 'patient' && (
                    <Button
                        variant="primary"
                        className="m-2"
                        onClick={handleShow}
                    >
                        Добавить ИРК1
                    </Button>
                )}
            </div>
            <DeleteModal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} confirmDelete={confirmDelete} />
            <EditModal show={showEditModal} onHide={() => setShowEditModal(false)} data={editData} onSubmit={handleEditSubmit} />
            <div>
                <style>
                    {`
                    .dynamic_table {
                        display: block;
                        max-width: 100%;
                        margin: 0 auto;
                        overflow: auto;
                        border: none;
                        margin-bottom: 20px;
                        border-collapse: separate;
                    }
                    .dynamic_table thead th,
                    .dynamic_table.table2 tbody tr:first-of-type td {
                        font-weight: bold;
                        text-align: left;
                        border: none;
                        padding: 10px 10px;
                        background: #cfe2ff;
                        font-size: 14px;
                        text-align: center;
                        table-layout: fixed;
                    }
                    .dynamic_table tbody td {
                        text-align: left;
                        border: none;
                        padding: 10px 10px;
                        font-size: 14px;
                        text-align: center;
                    }
                    .dynamic_table thead tr th:first-child,
                    .dynamic_table tbody tr td:first-child {
                        border-radius: 8px 0 0 8px;
                    }
                    .dynamic_table thead tr th:last-child,
                    .dynamic_table tbody tr td:last-child {
                        border-radius: 0 8px 8px 0;
                    }
                    .dynamic_table tbody tr:nth-child(even) {
                        background: #f2f2f2;
                    }
                    .dynamic_table tr td:first-of-type,
                    .dynamic_table tr th:first-of-type {
                        position: sticky;
                        font-weight: bold;
                        left: 0;
                        top: -10px;
                        z-index: 10;
                        opacity: 1;
                        background-color: #cfe2ff;
                        text-wrap:nowrap;
                        
                    }
                    .dynamic_table.table2 tbody tr:first-of-type td,
                    .dynamic_table tr th {
                        position: sticky;
                        font-weight: bold;
                        top: 0;
                        left: -10;
                        z-index: 10;
                        opacity: 1;
                        background-color: #cfe2ff;
                        text-wrap:nowrap;
                    }
                    .asd {
                        z-index: 100 !important;
                    }`}
                </style>
              
                <div className='overflow-x-auto'>
                {Array.isArray(patientIRC) && patientIRC.length > 0 ? (
                    <table className='dynamic_table'>
                        <thead>
                            <tr>
                                {Object.keys(headersMapping).map((key) => (
                                    <th key={key} className={asd[key]}>{headersMapping[key]}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {patientIRC.map((dataInstance, index) => (
                                
                                
                                <tr key={index} className='bg-purple-200'>
                                    {Object.keys(headersMapping).map((key) => {
                                        const value = dataInstance[key];
                                        
                                        let displayValue;
                                           // Format the value as per the logic in <Col>
                                        if(key=="created_at"){displayValue = format(new Date(value), 'dd/MM/yyyy')}
                                     
                                        else if (key === "drives_vehicle" || key === "involved_in_car_accident" || key === "red_signs_varices") {
                                            displayValue = value ? "Есть" : "Нет";
                                        } else if (key === "liver_conditions" || key === "comorbidities") {
                                            displayValue = translateArrayValues(key, value).join(", ");
                                        } else if (key === "blood_ammonia_test_result") {
                                            displayValue = `${value} µmol/L`;
                                        } else if (key === "liver_elastography_result" || key === "spleen_elastography_result") {
                                            displayValue = `${value} kPa`;
                                        } else if (["ethnicity", "region", "education_level", "marital_status", "job_type", "varices_degree", "ascites_status"].includes(key)) {
                                            displayValue = translateValue(key, value);
                                        } else {
                                            displayValue = value;
                                        }

                                        return <td key={key}>{displayValue === null || displayValue.length === 0 ? "Нет данных" : displayValue}</td>;
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ):null}
                </div>
                <Row>
                    <Accordion defaultActiveKey="0" alwaysOpen>
                        {Array.isArray(patientIRC) && patientIRC.length > 0 ? (
                            patientIRC.map((dataInstance, index) => (
                                <Accordion.Item eventKey={String(index)} key={index}>
                                    <Accordion.Header>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <span>Запись ИРК1</span>
                                            <span className='mx-2'>{format(new Date(dataInstance.created_at), 'dd/MM/yyyy')}</span>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div key={dataInstance.id}>
                                            <div className="d-flex align-items-center justify-content-between">
                                                <h4 className="text-center">Данные пациента</h4>
                                                {currentUserData.role !== 'patient' && (

                                                    <div className="text-end">

                                                        <button className="btn btn-primary me-2" onClick={() => openEditModal(dataInstance)}>Редактировать</button>

                                                        <button className="btn btn-danger"
                                                            onClick={() => openDeleteModal(dataInstance.id)}>Удалить
                                                        </button>
                                                    </div>
                                                )}
                                            </div>
                                            <hr />
                                            <Row>
                                                <Col md={6} className="pb-3 mb-3 border-bottom">
                                                    <strong className="text-dark">Этническая принадлежность: </strong>
                                                    <span className="d-block">{translateValue('ethnicity', dataInstance.ethnicity)}</span>
                                                </Col>
                                                <Col md={6} className="pb-3 mb-3 border-bottom">
                                                    <strong className="text-dark">Регион (Казахстан): </strong>
                                                    <span className="d-block">{translateValue('region', dataInstance.region)}</span>
                                                </Col>
                                                <Col md={6} className="pb-3 mb-3 border-bottom">
                                                    <strong className="text-dark">Рост, см: </strong>
                                                    <span className="d-block">{dataInstance.height}</span>
                                                </Col>
                                                <Col md={6} className="pb-3 mb-3 border-bottom">
                                                    <strong className="text-dark">Уровень образования: </strong>
                                                    <span className="d-block">{translateValue('education_level', dataInstance.education_level)}</span>
                                                </Col>
                                                <Col md={6} className="pb-3 mb-3 border-bottom">
                                                    <strong className="text-dark">Семейное положение: </strong>
                                                    <span className="d-block">{translateValue('marital_status', dataInstance.marital_status)}</span>
                                                </Col>
                                                <Col md={6} className="pb-3 mb-3 border-bottom">
                                                    <strong className="text-dark">Тип работы: </strong>
                                                    <span className="d-block">{translateValue('job_type', dataInstance.job_type)}</span>
                                                </Col>
                                                <Col md={6} className="pb-3 mb-3 border-bottom">
                                                    <strong className="text-dark">Водит транспортное средство: </strong>
                                                    <span className="d-block">{dataInstance.drives_vehicle ? 'Есть' : 'Нет'}</span>
                                                </Col>
                                                <Col md={6} className="pb-3 mb-3 border-bottom">
                                                    <strong className="text-dark">Был ли вовлечен в автомобильную аварию: </strong>
                                                    <span className="d-block">{dataInstance.involved_in_car_accident ? 'Есть' : 'Нет'}</span>
                                                </Col>
                                                <Col md={6} className="pb-3 mb-3 border-bottom">
                                                    <strong className="text-dark">Состояния печени: </strong>
                                                    <span className="d-block">{translateArrayValues('liver_conditions', dataInstance.liver_conditions).join(', ')}</span>
                                                </Col>
                                                <Col md={6} className="pb-3 mb-3 border-bottom">
                                                    <strong className="text-dark">Длительность заболевания печени (в годах): </strong>
                                                    <span className="d-block">{dataInstance.liver_disease_duration_years}</span>
                                                </Col>
                                                <Col md={6} className="pb-3 mb-3 border-bottom">
                                                    <strong className="text-dark">Результат теста на аммиак в крови: </strong>
                                                    <span className="d-block">{dataInstance.blood_ammonia_test_result} µmol/L</span>
                                                </Col>
                                                <Col md={6} className="pb-3 mb-3 border-bottom">
                                                    <strong className="text-dark">Результат эластографии печени: </strong>
                                                    <span className="d-block">{dataInstance.liver_elastography_result} kPa</span>
                                                </Col>
                                                <Col md={6} className="pb-3 mb-3 border-bottom">
                                                    <strong className="text-dark">Результат эластографии селезенки: </strong>
                                                    <span className="d-block">{dataInstance.spleen_elastography_result} kPa</span>
                                                </Col>
                                                <Col md={6} className="pb-3 mb-3 border-bottom">
                                                    <strong className="text-dark">Степень варикозного расширения: </strong>
                                                    <span className="d-block">{translateValue('varices_degree', dataInstance.varices_degree)}</span>
                                                </Col>
                                                <Col md={6} className="pb-3 mb-3 border-bottom">
                                                    <strong className="text-dark">Наличие красных знаков на варикозных венах: </strong>
                                                    <span className="d-block">{dataInstance.red_signs_varices ? 'Есть' : 'Нет'}</span>
                                                </Col>
                                                <Col md={6} className="pb-3 mb-3 border-bottom">
                                                    <strong className="text-dark">Статус асцита: </strong>
                                                    <span className="d-block">{translateValue('ascites_status', dataInstance.ascites_status)}</span>
                                                </Col>
                                                <Col md={6} className="pb-3 mb-3 border-bottom">
                                                    <strong className="text-dark">Сопутствующие заболевания: </strong>
                                                    <span className="d-block">
                                                        {translateArrayValues('comorbidities', dataInstance.comorbidities).join(', ') === null || translateArrayValues('comorbidities', dataInstance.comorbidities).join(', ').length === 0 ? "Нет данных" : translateArrayValues('comorbidities', dataInstance.comorbidities).join(', ')
                                                        }
                                                    </span>
                                                </Col>
                                            </Row>

                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))
                        ) : (
                            <p>Данных по ИРК1 пациента нет</p>
                        )}
                    </Accordion>

                </Row>
            </div>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Добавить ИРК1</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        handleSubmit(values);
                        setSubmitting(false);
                    }}
                >
                    {(formik) => (
                        <Form noValidate onSubmit={formik.handleSubmit}>
                            <Modal.Body>
                                {PatientInputType.map((item) => renderFormField(item, formik))}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Закрыть
                                </Button>
                                <Button variant="primary" type="submit" className="btn btn-primary">
                                    Отправить
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>



        </Container>

    )

}
export default TabIC1;