import React from 'react';
import AuthContext from './authContext';

const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(!!localStorage.getItem('accessToken'));
  const [redirectTo, setRedirectTo] = React.useState(null);
  const base_url = process.env.REACT_APP_BASE_URL;
  async function login(login, password) {
    const response = await fetch(base_url + '/auth/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        username: login,
        password: password,
      }),
    });

    if (!response.ok) {
      throw new Error(`Error logging in. Status code: ${response.status}`);
    }

    setIsAuthenticated(true);

    const data = await response.json();
    const { access_token, refresh_token, user_role, user_id } = data;
    localStorage.setItem('accessToken', access_token);
    localStorage.setItem('refreshToken', refresh_token);

    const userData = { 'login': login, 'role': user_role, 'id': user_id };
    localStorage.setItem('currentUserData', JSON.stringify(userData));
    
    let newHomelink = '/';
    if (user_role === "patient") {
      newHomelink = `/patient/${user_id}/patient`;
    } else if (user_role === "doctor") {
      newHomelink = `/doctor/${user_id}/doctor`;
    } else if (user_role === "admin") {
      newHomelink = `/admin/${login}/all_patients`;
    }
    localStorage.setItem('homelink', newHomelink);
    setRedirectTo(newHomelink);

    return response;
  }

  async function refresh() {
    const refreshToken = localStorage.getItem("refreshToken");
    const refresh = {
      "refresh_token": refreshToken
    }

    if (!refreshToken) {
      setIsAuthenticated(false);
      setRedirectTo('/login');
    }

    const response = await fetch(base_url + '/auth/refresh', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(refresh)
    });

    if (!response.ok) {
      setIsAuthenticated(false);
      setRedirectTo('/login');
      throw new Error(`Error occurred while refreshing token. Status code: ${response.status}`);
    }

    const data = await response.json();
    const { access_token, refresh_token } = data;
    localStorage.setItem('accessToken', access_token);
    localStorage.setItem('refreshToken', refresh_token);

    return response;
  }

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, login, refresh, redirectTo, setRedirectTo}}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;