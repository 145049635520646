import AddPatientModalForm from "../../adminAddPatientMenu/new/adminAddPatientMenu";
import Button from "react-bootstrap/Button";

import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import DeleteButtonIcon from "../icons/deleteIcon.png";
import EditButtonIcon from "../icons/editIcon.png";
import AdminMain from "../adminMain";
import PageContext from '../../../../contexts/pageContext';
import AuthContext from "../../../../auth/authContext";
import { Modal} from 'react-bootstrap';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import icon from '../icons//icons8-вопрос-48.png';
import icondelete from '../icons/icons8-delete.svg';
import iconedit from '../icons/icons8-edit.svg';
import iconpassword from '../icons/icons8-password.svg';
import PaginationComponent from '../../../pagination/pagination'

import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import {renderIntoDocument} from "react-dom/test-utils";

const PatientList = ({patients,setSearchPatientsResults,searchPatientButtonHandle,  }) => {
    const { refresh, setIsAuthenticated, redirectTo, setRedirectTo } = useContext(AuthContext);
    const [dataLoading, setDataLoading] = useState(true);
    const [patientsData, setPatientsData] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [prevTotalPages, setPrevTotalPages] = useState(0);
    const [isSavePatientMenuOpened, setIsSavePatientMenuOpened] = useState(false);
    const [doctorList, setDoctorList] = useState([]);
    const [show, setShow] = useState(false)
    const navigate = useNavigate();
    const handleClose = () => {
        setShow(false)};
    const handleShow = () => setShow(true);
    const {currentPage, handlePageChange, prevCurrentPage, setPrevCurrentPage } = useContext(PageContext);
    const base_url=process.env.REACT_APP_BASE_URL;
    const [editPatient, setEditPatient] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [deletePatient, setDeletePatient] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [editPassword, setEditPassword] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');

    let accessToken = localStorage.getItem('accessToken');

    useEffect(() => {
        // Fetch the list of doctors
        fetch(base_url + '/doctors?limit=1000', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        })
            .then((response) => response.json())
            .then((data) => setDoctorList(data.doctors))
            .catch((error) => {
                console.error('Error fetching doctors:', error);
            });
    }, [accessToken, base_url]);

    function generateRandomString(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for ( let i = 0; i < length; i++ ) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const downloadCSV = async () => {
    
        try {
            const response = await axios.get(base_url + '/patients/export', {
                responseType: 'blob',
                headers: {
                    'Authorization': `Bearer ${accessToken}`  // Add the authorization header
                }
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'patients.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error downloading the CSV file', error);
        }
    };

     // Function to open the password change modal
    const handleOpenPasswordModal = (patient) => {
        setEditPassword(patient);
        setNewPassword(generateRandomString(6)); // Assuming you have a function to generate a new password
        setShowPasswordModal(true);
    };

    // Function to close the password change modal
    const handleClosePasswordModal = () => {
        setShowPasswordModal(false);
        setEditPassword(null);
        setNewPassword('');
    };

    // Function to handle password change
    const handlePasswordChange = () => {
        try {
            let accessToken = localStorage.getItem('accessToken');
            console.log("Changing password for patient:", editPassword);
            fetch(base_url+`/patients/${editPassword.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                },
                body: JSON.stringify({ hashed_password: newPassword}),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Failed to update patient');
                    }
                    console.log(`Patient with ID ${editPassword.id} updated successfully`);
                    // Optionally, refresh the patient list or update the UI here
                })
                .catch((error) => {
                    console.error('Error:', error);
                });
            handleClosePasswordModal();
        } catch (error) {
            console.error('Error changing password:', error);
            alert('Failed to change password. Please try again.');
        }
    };

    const handleDeletePatient = (id) => {
        const currentUserData = localStorage.getItem('currentUserData');
        let accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');


        fetch(base_url+`/patients/${id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to delete patient');
                }
                handleReload();
                console.log(`Patient with ID ${id} deleted successfully`);
                // Optionally, you can refresh the patient list here
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
    const handleEditPatient = (patient) => {
        const currentUserData = localStorage.getItem('currentUserData');
        let accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        const updatedPatient = {

            surname: patient.surname,
            name: patient.name,
            patronymic: patient.patronymic,
            IIN: patient.IIN,
            doctor_id: patient.doctor_id,
        };


        fetch(base_url+`/patients/${patient.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken
            },
            body: JSON.stringify(updatedPatient),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Failed to update patient');
                }
                console.log(`Patient with ID ${patient.id} updated successfully`);
                // Optionally, refresh the patient list or update the UI here
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };
    const handleDeleteClick = (patient) => {
        setDeletePatient(patient);
        setShowDeleteModal(true);
    };
    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Подсказка</Popover.Header>
            <Popover.Body>
                Нажмите дважды на строку в таблице чтобы открыть профиль
            </Popover.Body>
        </Popover>
    );
    // Function to confirm and delete the patient
    const handleConfirmDelete = () => {
        // Here you would normally call an API to delete the patient
        console.log("Deleting patient:", deletePatient);
        handleDeletePatient(deletePatient);
        // Close the modal after deletion
        setShowDeleteModal(false);
    };

    // Function to handle edit button click
    const handleEditClick = (patient) => {
        setEditPatient(patient);

        setShowEditModal(true);
    };

    // Function to handle changes in the edit form
    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditPatient((prev) => ({ ...prev, [name]: value }));
    };

    // Function to handle saving the edited patient
    const handleSaveEdit = () => {
        console.log("Saving edited patient:", editPatient);
        handleEditPatient(editPatient);

        handleReload();
        // Close the modal after saving
        setShowEditModal(false);
    };

    const handleDoubleClick = (id) => {
        navigate('/patient/'+id+'/patient');
    };
    const handleLogout = () => {
        setIsAuthenticated(false);
        setRedirectTo('/login');
        localStorage.removeItem('currentUserData');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
    };

    const fetchPatientsData = async (search = '') => {
        const currentUserData = localStorage.getItem('currentUserData');
        let accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');

        if (!currentUserData) {
            handleLogout();
            return;
        }
        if (!accessToken) {
            if (refreshToken) {
                await refresh();
                accessToken = localStorage.getItem('accessToken');
            } else {
                handleLogout();
                return;
            }
        }
        try {
            let response = await fetch(`${base_url}/patients?page=${currentPage}&search=${search}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                }
            });


            if (response.status === 401) {
                try {
                    await refresh();
                    accessToken = localStorage.getItem('accessToken');
                    response = await fetch(`${base_url}/patients?page=${currentPage}&search=${search}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + accessToken
                        }
                    });
                } catch (error) {
                    handleLogout();
                }
            }
            else {
                const patientsData = await response.json();
                setPatientsData(patientsData.patients);
                setTotalPages(patientsData.total_pages);
                setDataLoading(false);
                console.log("Response Status:", response.status);
                console.log("Patients Data:", patientsData);
            }
        } catch (error) {
            console.log("An error ocured while trying to fetch the patients data.")
        }
    };

    useEffect(() => {
        fetchPatientsData();
    }, [currentPage]);

    // TODO: Need add in deps function for catching new element

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSearchClick = () => {
        setPrevCurrentPage(currentPage);
        handlePageChange(1);
        fetchPatientsData(searchQuery);
    };

    console.log("Total Pages:", totalPages);
    const handleReload = () => {
        fetchPatientsData(); // Trigger fetch data
    };

    if (dataLoading) {
        return (
            <div className='loader'></div>
        );
    }


    const openSavePatientMenuHandle = () => {
        setIsSavePatientMenuOpened(true);
        document.documentElement.style.overflowY = 'hidden';
    }
    return(
            <div className='adminMain '>
                <AddPatientModalForm show={show} handleClose={handleClose}  hideDoctorList={false}/>
                <div className='d-flex justify-content-between my-3'>
                    <h2>Пациенты в базе данных</h2>
                    <OverlayTrigger trigger="click" placement="left" overlay={popover}>
                        <img
                            src={icon}
                            alt="Icon Description"
                            style={{width: '35px', height: '35px', cursor: 'pointer'}}
                        />
                    </OverlayTrigger>
                </div>

                <div className="card card-line bg-body-tertiary border-transparent mb-7">
                    <div className="card-body p-4">
                        <div className="row align-items-center">
                            <div className="col-12 col-lg-auto mb-3 mb-lg-0">
                                <button type="button" className="btn btn-success btn-color-add-patient"
                                        onClick={handleShow}>Добавить пациента
                                </button>
                            </div>
                            <div className="col-12 col-lg">
                                <div className="row gx-3">
                                    <div className="col col-lg-5 ms-auto">
                                        <div className="input-group bg-body">
                                            <input type="search" className="form-control searchBar"
                                                   placeholder="Найти пациента по ФИО или ИИН"
                                                   aria-label="search" aria-describedby="search"
                                                   onChange={handleSearchChange}
                                            />
                                            <button className="input-group-text searchButton" id="search" onClick={handleSearchClick}>
                                            <span className="material-symbols-outlined">Поиск</span>
                                        </button>
                                        </div>
                                    </div>

                                    <div className="col-auto">
                                        <div className="dropdown">
                                            <button className="btn btn-dark px-3" onClick={openSavePatientMenuHandle}>
                                                <span className="material-symbols-outlined" onClick={downloadCSV}>Выгрузить пациентов</span>
                                            </button>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <table className="table table-striped  table-hover">
                    <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Фамилия</th>
                            <th scope="col">Имя</th>
                            <th scope="col">Отчество</th>
                            <th scope="col">ИИН</th>
                            <th scope="col" className='col-1'>Действия</th>
                        </tr>
                    </thead>
                    <tbody>
                        {patientsData && patientsData.length > 0 ? (
                            patientsData.map((patient) => (
                                <React.Fragment key={patient.id}>
                                    <tr onDoubleClick={() => handleDoubleClick(patient.id)}>
                                    <th scope="row">{patient.id}</th>
                                    <td>{patient.surname}</td>
                                    <td>{patient.name}</td>
                                    <td>{patient.patronymic}</td>
                                    <td>{patient.IIN}</td>
                                    <td>
                                        <div className="d-flex flex-row">
                                            <div>
                                                <button  type="button"
                                                         className="btn"
                                                         onClick={() => handleEditClick(patient)}>
                                                    <i className="material-icons text-warning"><img
                                                        src={iconedit}
                                                        alt="Icon Description"
                                                        style={{width: '20px', height: '20px', cursor: 'pointer'}}
                                                    /> </i>
                                                </button>
                                            </div>
                                            <div>
                                                <button  type="button"
                                                         className="btn"
                                                         onClick={() => handleOpenPasswordModal(patient)}>
                                                    <i className="material-icons text-warning"><img
                                                        src={iconpassword}
                                                        alt="Icon Description"
                                                        style={{width: '20px', height: '20px', cursor: 'pointer'}}
                                                    /> </i>
                                                </button>
                                            </div>
                                            <div>
                                                <button type="button"
                                                        className="btn"
                                                        onClick={() => handleDeleteClick(patient.id)}
                                                >
                                                    <i className="material-icons text-danger"> <img
                                                        src={icondelete}
                                                        alt="Icon Description"
                                                        style={{width: '20px', height: '20px', cursor: 'pointer'}}
                                                    /> </i>
                                                </button>
                                            </div>
                                        </div>
                                    </td>

                                    </tr>
                                </React.Fragment>
                            ))
                        ) : (
                            <tr>
                                <td>No patients found.</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <PaginationComponent currentPage={currentPage} totalPages={totalPages} />
                {/* Edit Modal */}
                {editPatient && (
                    <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
                        <Modal.Header closeButton>
                            <Modal.Title>Редактировать пациента</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form>
                                <div className="form-group">
                                    <label>Фамилия</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="surname"
                                        value={editPatient.surname}
                                        onChange={handleEditChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Имя</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="name"
                                        value={editPatient.name}
                                        onChange={handleEditChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Отчество</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="patronymic"
                                        value={editPatient.patronymic}
                                        onChange={handleEditChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>ИНН</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="IIN"
                                        value={editPatient.IIN}
                                        onChange={handleEditChange}
                                    />
                                </div>
                                <div className="form-group">
                                    <label>Выберите доктора</label>
                                    <select
                                        name="doctor_id"
                                        value={editPatient.doctor_id}
                                        class="form-select"
                                        onChange={(e) => {
                                            handleEditChange({
                                                target: {
                                                    name: e.target.name,
                                                    value: Number(e.target.value), // Convert the selected value to a number
                                                },
                                            });
                                        }}
                                    >
                                        <option value="">Выберите доктора из списка</option>
                                        {Array.isArray(doctorList) && doctorList.map((doctor) => (
                                            <option key={doctor.id} value={doctor.id}>
                                                {doctor.surname} {doctor.name} {doctor.patronymic} - {doctor.qualification.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowEditModal(false)}>
                                Отмена
                            </Button>
                            <Button variant="primary" onClick={handleSaveEdit}>
                                Сохранить
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
                {/* Delete Confirmation Modal */}
                {deletePatient && (
                    <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} centered size='sm'>
                        <Modal.Header closeButton>
                            <Modal.Title>Удалить пациента</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            Вы уверены, что хотите удалить пациента {deletePatient.surname} {deletePatient.name}?
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                Отмена
                            </Button>
                            <Button variant="danger" onClick={handleConfirmDelete}>
                                Удалить
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
                {/* Password Change Modal */}
                {editPassword && (
                    <Modal show={showPasswordModal} onHide={() => setShowPasswordModal(false)} centered size='sm'>
                        <Modal.Header closeButton>
                            <Modal.Title>Сменить пароль</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="mb-3">
                                <label htmlFor="newPassword" className="form-label">Новый пароль</label>
                                <input type="text" className="form-control" id="hashed_password" value={newPassword} onChange={(e) => setNewPassword(e.target.value)}/>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShowPasswordModal(false)}>
                                Отмена
                            </Button>
                            <Button variant="primary" onClick={handlePasswordChange}>
                                Сохранить
                            </Button>
                        </Modal.Footer>
                    </Modal>
                )}
            </div>
        )
}
export default PatientList;
