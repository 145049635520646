import './patientPage.scss';
import PatientHeader from '../patientHeader/patientHeader';
import PatientMainNew from '../patientMain/new/patientMain';
import PatientMain from '../patientMain/patientMain';
import PatientTest from '../patientTest/patientTest';
import PatientStroopTest from '../patientTest/patientStroopTest';
import PatientDSTest from '../patientTest/patientDSTest';
import PatientConnectionTest from '../patientTest/patientConnectionTest';
import { Route, Routes, useParams } from 'react-router-dom';
import React from 'react';

const PatientPage = () => {
    const { patientIIN } = useParams();
    const { patientID } = useParams();

    return (
        <div className='patientPage'>
            <PatientHeader patientID={patientID} />
            <Routes>
                <Route path={`patient/*`} element={<PatientMainNew patientID={patientID}/>} />
                <Route path={`account/*`} element={<PatientMain patientIIN={patientIIN} />} />
                <Route path={`tests/*`} element={<PatientTest patientID={patientID} />} />
                <Route path={`tests/stroop/*`} element={<PatientStroopTest patientID={patientID}/>} />
                <Route path={`tests/dst/*`} element={<PatientDSTest patientID={patientID} />} />
                <Route path={`tests/connection/*`} element={<PatientConnectionTest patientID={patientID} />} />
            </Routes>
        </div>
    );
}

export default PatientPage;
