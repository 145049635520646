import { useContext, useEffect, useRef, useState } from 'react';
import { Modal, Button, Col, Form, Row, Alert } from 'react-bootstrap';
import * as formik from 'formik';
import * as yup from 'yup';

const AdminAddAdminMenu = ({ show, handleClose, handleReload }) => {
    const { Formik } = formik;
    const base_url = process.env.REACT_APP_BASE_URL;
    const [errorShow, setErrorShow] = useState(false);
    let accessToken = localStorage.getItem('accessToken');

    useEffect(() => {
        if (handleClose) {
            setErrorShow(false);
        }
    }, [handleClose]);

    function generateRandomString(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const randomString = generateRandomString(6);
    const schema = yup.object().shape({
        surname: yup.string().min(2, 'Минимум 2 буквы').required('Обязательное поле'),
        name: yup.string().min(2, 'Минимум 2 буквы').max(50, 'Максимум 50 букв').required('Обязательное поле'),
        username: yup.string().required('Обязательное поле'),
        hashed_password: yup.string().required('Заполните поле пароля'),
    });

    const sendFormData = async (data) => {
        let accessToken = localStorage.getItem('accessToken');

        try {
            const response = await fetch(base_url + '/admins', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                },
                body: JSON.stringify(data),
            });
            if (!response.ok) {
                if (response.status === 400) {
                    const errorData = await response.json();
                    throw new Error(`Bad Request: ${errorData.message}`);
                } else {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            }
            const result = await response.json();
            console.log('Form submitted successfully:', result);
            handleReload();
            handleClose(); // Close the modal after successful submission

        } catch (error) {
            console.error("Error sending form:", error);
            setErrorShow(true);
        }
    };

    return (
        <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Добавить администратора</Modal.Title>
            </Modal.Header>
            <Formik
                validationSchema={schema}
                onSubmit={(values) => {
                    sendFormData(values).then(r => console.log('fd'));
                }}
                initialValues={{
                    surname: '',
                    name: '',
                    patronymic: '',
                    username: '',
                    hashed_password: randomString,
                }}>
                {({ handleSubmit, handleChange, values, touched, errors, handleBlur }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Modal.Body>
                            <Alert variant="danger" show={errorShow} dismissible onClose={() => setErrorShow(false)}>
                                <Alert.Heading>Ошибка добавления администратора</Alert.Heading>
                                <p>
                                    Данный администратор уже существует в базе
                                </p>
                            </Alert>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="surname">
                                    <Form.Label>Фамилия</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="surname"
                                        value={values.surname}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        isInvalid={touched.surname && errors.surname ? errors.surname : null}
                                        placeholder="Введите фамилию"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.surname}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} controlId="name">
                                    <Form.Label>Имя</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.name && errors.name ? errors.name : null}
                                        placeholder="Введите имя"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} controlId="patronymic">
                                    <Form.Label>Отчество</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="patronymic"
                                        value={values.patronymic}
                                        onChange={handleChange}
                                        placeholder="Введите отчество при наличии"
                                    />
                                </Form.Group>
                            </Row>
                            <Row>
                                <Col xs="auto">
                                    <Form.Group className="mb-3" controlId="username">
                                        <Form.Label>Логин</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="username"
                                            value={values.username}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={touched.username && errors.username ? errors.username : null}
                                            placeholder="Введите уникальный логин"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.username}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <hr />
                            <Row className="mb-3">
                                <Col xs="auto">
                                    <Form.Group className="mb-3" controlId="hashed_password">
                                        <Form.Label>Установить пароль для Администратора</Form.Label>
                                        <Form.Control
                                            placeholder="Введите пароль для администратора"
                                            value={values.hashed_password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            isInvalid={touched.hashed_password && errors.hashed_password ? errors.hashed_password : null}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.hashed_password}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Отменить
                            </Button>
                            <Button variant="primary" type="submit" onClick={handleSubmit}>
                                Сохранить
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}

export default AdminAddAdminMenu;