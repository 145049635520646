import './loginPage.scss';
import logoImage from './icons/favicon.ico';
import AuthContext from '../../auth/authContext';
import eyeOff from './icons/eyeIcons/eyeOff.svg';
import eye from './icons/eyeIcons/eye.svg'
import Alert from 'react-bootstrap/Alert';

import { Navigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from 'react';

const LoginPage = () => {
    const [form, setForm] = useState({
        userInput: '',
        password: '',
    });

    const { login, redirectTo, setRedirectTo } = useContext(AuthContext);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
        if (redirectTo) {
            setRedirectTo(null);
        }
    }, [redirectTo, setRedirectTo]);


    const handleChange = (e) => {
        setForm({
        ...form,
        [e.target.name]: e.target.value,
        });
    };
    const [show, setShow] = useState(false);


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await login(form.userInput, form.password, form.role);
        } catch (error) {
            if (error.message.includes('401') || error.message.includes('422')) {
                setErrorMessage("Ошибка авторизации. Проверьте данные, выбранную роль и попробуйте снова.");
 setShow(true)
            } else {
                setErrorMessage("Возникла какая-то ошибка, попробуйте позже.");
                setShow(true)
            }
        }
    };


    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(eyeOff);
    const handleToggle = () => {
        if (type==='password'){
            setIcon(eye);
            setType('text')
        } else {
            setIcon(eyeOff)
            setType('password')
        }
    }

if (redirectTo) {
        return <Navigate to={redirectTo} replace />;
    }

    return (

        <div className='loginWrapper'>
            <div className='loginPage'>
                <div className='row'>
                    {show &&
                        <Alert variant="danger" onClose={() => setShow(false)} dismissible>
                            <Alert.Heading>Ошибка авторизации</Alert.Heading>
                            <p>
                                {errorMessage}
                            </p>
                        </Alert>
                    }
                </div>
                <img className='logoImage' src={logoImage} alt=''></img>
                <div className="login_box container ">
                    <form className='loginForm gap-4' id="loginForm" onSubmit={handleSubmit}>
                        <div>
                            <h2 className='fw-semibold text-center'>Вход в кабинет</h2>
                        </div>
                        <div>
                            <label htmlFor="userInput" className='form-label fs-6 mb-2'>ИИН или имя
                                пользователя:</label>
                            <input type="text" id="userInput" name="userInput" value={form.userInput}
                                   className='form-control'
                                   onChange={handleChange}/>
                            <label htmlFor="password" className='form-label fs-6'>Пароль:</label>
                            <div className="mb-2 flex input-group">
                                <input type={type} id="password" name="password" value={form.password}
                                       className='form-control'
                                       onChange={handleChange}/>
                                <span className="flex justify-around items-center input-group-text"
                                      onClick={handleToggle}>
                                    <img className="input-icon" src={icon}/>
                                </span>
                            </div>
                        </div>
                        <div className='d-grid'>
                            <button className='submitButton btn btn-primary fs-6 fw-semibold' type="submit"> Войти
                            </button>
                        </div>

                    </form>

                </div>
            </div>
        </div>
    );
}

export default LoginPage;
