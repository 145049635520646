import symbol1 from './symbol-1.png';
import symbol2 from './symbol-2.png';
import symbol3 from './symbol-3.png';
import symbol4 from './symbol-4.png';
import symbol5 from './symbol-5.png';
import symbol6 from './symbol-6.png';
import symbol7 from './symbol-7.png';
import symbol8 from './symbol-8.png';

const symbols = {
    1: symbol1,
    2: symbol2,
    3: symbol3,
    4: symbol4,
    5: symbol5,
    6: symbol6,
    7: symbol7,
    8: symbol8
};

export default symbols;