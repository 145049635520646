export const PatientInputType=[
    { label: 'ethnicity', name: 'Этническая принадлежность', type:'select'},
    { label: 'region', name: 'Регионы',  type:'select'},
    { label: 'height', name: 'Рост, см', type: 'number'},
    { label: 'education_level', name: 'Уровень образование', type:'select' },
    { label: 'marital_status', name: 'Семейное положение',  type:'select' },
    { label: 'job_type', name: 'С чем связана ваша работа?',  type:'select' },
    { label: 'drives_vehicle', name: 'Водите ли транспортное средство?', type:'boolean' },
    { label: 'involved_in_car_accident', name: 'Были ли вы участником ДТП за последний год?', type:'boolean' },
    { label: 'liver_conditions', name: 'Цирроз печени в исходе',  type:'multiselect'  },
    { label: 'liver_disease_duration_years', name: 'Известная продолжительность болезни печени?(Указать год постановки диагноза ЦП)',type: 'number' },
    { label: 'blood_ammonia_test_result', name: 'Результат экспресс теста (аммиак крови), мкмоль/л', type: 'number'},
    { label: 'liver_elastography_result', name: 'Результат непрямой эластографии печени, стадия фиброза, kPa', type: 'number'},
    { label: 'spleen_elastography_result', name: 'Результат непрямой эластографии селезенки, kPa',type: 'number' },
    { label: 'varices_degree', name: 'Наличие ВРВ',type:'select' },
    { label: 'red_signs_varices', name: 'Красные знаки ВРВ', type:'boolean' },
    { label: 'ascites_status', name: 'Наличие асцита', type:'select'  },
    { label: 'comorbidities', name: 'Сопутствующие заболевания',type:'multiselect' }
]
