import {FormSelectionIC2Options} from "../../fieldsPatientsData/formSelectionIC2Options";
import React, { useContext, useEffect, useState, useMemo } from 'react';
import AuthContext from "../../../../../auth/authContext";
import * as Yup from "yup";
import {ErrorMessage, Field, Formik} from "formik";
import {PatientIC2InputType} from "../../fieldsPatientsData/patientIC2InputType";
import {Modal,Form,Button,Container,Row,Accordion,Col} from "react-bootstrap";
import Select from "react-select";
import { format, toDate } from 'date-fns';

const TabIC2 =({patientID}) => {
    const [patientIRC2, setPatientIRC2] = useState(false);
    const [showIC2, setShowIC2] = useState(false);
    const [errorShow, setErrorShow] = useState(false);
    const {refresh, setIsAuthenticated, redirectTo, setRedirectTo } = useContext(AuthContext);
    const [isLoggedOut, setIsLoggedOut] = useState(false);
    const handleShowIC2 = () => setShowIC2(true);
    const handleCloseIC2 = () =>  setShowIC2(false);
    const base_url = process.env.REACT_APP_BASE_URL;
    const [dataLoading, setDataLoading] = useState(true);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [editData, setEditData] = useState(null);
    const [deleteId, setDeleteId] = useState(null);
    const currentUserData = JSON.parse(localStorage.getItem('currentUserData') || '{}');
    let i = 0;
    const handleSubmitIC2 = (values) => {
        values['patient_id']=patientID;
        console.log('Form Data:', values);
        sendFormDataIC2(values);
    }

    const generateValidationSchema = (fields) => {
        const schemaFields = {};

        fields.forEach((category) => {
            category.data.forEach((item) => {
                switch (item.type) {
                    case 'number':
                        schemaFields[item.label] = Yup.number()
                            .typeError(`${item.name} должно быть числом`)
                            .required(`Поле ${item.name} является обязательным`);
                        break;
                    case 'input':
                        schemaFields[item.label] = Yup.string()
                            .required(`Поле ${item.name} является обязательным`);
                        break;
                    case 'select':
                        schemaFields[item.label] = Yup.string()
                            .required(`Выберите однo из значений`);
                        break;
                    case 'multiselect':
                        schemaFields[item.label] = Yup.array()
                            .of(Yup.string())
                            .required(`Выберите хотя бы одно значение из списка`);
                        break;
                    case 'boolean':
                        schemaFields[item.label] = Yup.boolean()
                            .required(`${item.name} является обязательным`);
                        break;
                    default:
                        schemaFields[item.label] = Yup.string();
                }
            });
        });

        return Yup.object().shape(schemaFields);
    };

    const validationSchemaIC2 = generateValidationSchema(PatientIC2InputType);


    const initialValuesIC2 = PatientIC2InputType.reduce((values, category) => {
        category.data.forEach((item) => {
            switch (item.type) {
                case 'select':
                case 'number':
                    values[item.label] = ''; // Initialize select and number fields with an empty string
                    break;
                case 'multiselect':
                    values[item.label] = []; // Initialize multiselect fields with an empty array
                    break;
                case 'boolean':
                    values[item.label] = false; // Initialize boolean fields with false
                    break;
                case 'input':
                    values[item.label] = ''; // Initialize input fields with an empty string
                    break;
                default:
                    values[item.label] = ''; // Default to empty string for any other type
            }
        });
        return values;
    }, {});


    const translateArrayValues2 = (key, value) => {
        if (FormSelectionIC2Options[key]) {
            const option = FormSelectionIC2Options[key].find((item) => item.label === value);
            return option ? option.translatedLabel : value;
        }
        return value;
    };

    const translateValue = (field, value) => {
        const fieldTranslations = FormSelectionIC2Options[field];
        if (!fieldTranslations) return value;
        const foundTranslation = fieldTranslations.find((item) => item.label === value);
        return foundTranslation ? foundTranslation.translatedLabel : value;
    };
    const translateArrayValues = (field, values) => {
        if (!Array.isArray(values)) return [];
        return values.map((value) => translateValue(field, value));
    };

    const fetchPatientIRC2Data = async () => {
        const currentUserData = localStorage.getItem('currentUserData');
        let accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');

        if (!currentUserData) {
            handleLogout();
            return;
        }

        if (!accessToken && refreshToken) {
            try {
                await refresh();
                accessToken = localStorage.getItem('accessToken');
            } catch (error) {
                handleLogout();
                return;
            }
        }

        try {
            let response = await fetch(base_url + `/individual-registration-card-part2/patient/${patientID}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                }
            });

            if (response.status === 401) {
                try {
                    await refresh();
                    accessToken = localStorage.getItem('accessToken');
                    response = await fetch(base_url + `/individual-registration-card-part2/patient/${patientID}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + accessToken
                        }
                    });
                } catch (error) {
                    handleLogout();
                    return;
                }
            }

            const patientIRC2 = await response.json();

            setPatientIRC2(patientIRC2);
            console.log(patientIRC2);
            setDataLoading(false);

        } catch (error) {
            console.log("An error occurred while trying to fetch the patient's data.");
            setDataLoading(false);
        }
    };
    useEffect(() => {
        if (patientID) {
            fetchPatientIRC2Data();
        }
    }, [patientID]);
    const handleLogout = () => {
        setIsAuthenticated(false);
        setRedirectTo('/login');
        localStorage.removeItem('currentUserData');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        setIsLoggedOut(true);
    }
    if (dataLoading) {
        return <div className='loader'></div>;
    }
    const renderFormFieldIC2 = (item, formik) => {
        const { values, setFieldValue } = formik;
        const customStyles = {
            control: (provided, state) => ({
                ...provided,
                borderColor: formik.touched[item.label] && formik.errors[item.label] ? 'red' : '#ced4da',
                boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : 'none',
                '&:hover': {
                    borderColor: formik.touched[item.label] && formik.errors[item.label] ? 'red' : '#80bdff',
                },
                minHeight: 'calc(1.5em + 0.75rem + 2px)', // Matches Bootstrap input height
            }),
            menu: (provided) => ({
                ...provided,
                zIndex: 9999, // Make sure the dropdown appears above other elements
            }),
        };

        switch (item.type) {
            case 'select':
                return (
                    <div key={item.label} className="form-group mb-2">
                        <label>{item.name}</label>
                        <Select
                            options={
                                FormSelectionIC2Options[item.label]?.map((option) => ({
                                    value: option.label, // Use `label` as the value
                                    label: option.translatedLabel, // Use `translatedLabel` as the displayed text
                                })) || []
                            }
                            value={
                                FormSelectionIC2Options[item.label]?.find(
                                    (option) => option.label === values[item.label]
                                ) && {
                                    value: values[item.label],
                                    label: FormSelectionIC2Options[item.label].find(
                                        (option) => option.label === values[item.label]
                                    )?.translatedLabel,
                                }
                            }
                            onChange={(option) =>
                                setFieldValue(item.label, option ? option.value : '')
                            }
                            styles={customStyles}
                            className={`react-select-container ${formik.touched[item.label] && formik.errors[item.label] ? 'is-invalid' : ''
                            }`}
                            placeholder="Выберите один из вариантов"
                        />
                        <ErrorMessage
                            name={item.label}
                            className="text-danger"
                            component="div"
                        />
                    </div>
                );
            case 'multiselect':
                return (
                    <div key={item.label} className="form-group mb-2">
                        <label>{item.name}</label>
                        <Select
                            isMulti
                            options={
                                FormSelectionIC2Options[item.label]?.map((option) => ({
                                    value: option.label, // Use `label` as the value
                                    label: option.translatedLabel, // Use `translatedLabel` as the displayed text
                                })) || []
                            }
                            value={
                                values[item.label]?.map((value) => {
                                    const matchedOption = FormSelectionIC2Options[item.label]?.find(
                                        (option) => option.label === value
                                    );
                                    return (
                                        matchedOption && {
                                            value: matchedOption.label,
                                            label: matchedOption.translatedLabel,
                                        }
                                    );
                                }) || []
                            }
                            onChange={(selectedOptions) => {
                                const selectedValues = selectedOptions
                                    ? selectedOptions.map((opt) => opt.value)
                                    : [];
                                setFieldValue(item.label, selectedValues);
                            }}
                            styles={customStyles}
                            className={`react-select-container ${formik.touched[item.label] && formik.errors[item.label] ? 'is-invalid' : ''
                            }`}
                            placeholder="Выберите один или несколько вариантов"
                        />
                        <ErrorMessage
                            name={item.label}
                            component="div"
                            className="text-danger"
                        />
                    </div>
                );
            case 'number':
                return (
                    <div key={item.label} className="form-group mb-2">
                        <label>{item.name}</label>
                        <Field
                            type="number"
                            name={item.label}
                            className={`form-control ${formik.touched[item.label] && formik.errors[item.label] ? 'is-invalid' : ''}`}
                            placeholder="Введите число"
                            onWheel={(e) => e.target.blur()} // Prevent number change on wheel scroll
                        />
                        <ErrorMessage name={item.label} component="div" className="text-danger"/>
                    </div>
                );
            case 'input':
                return (
                    <div key={item.label} className="form-group mb-2">
                        <label>{item.name}</label>
                        <Field
                            type="text"
                            name={item.label}
                            className={`form-control ${formik.touched[item.label] && formik.errors[item.label] ? 'is-invalid' : ''}`}
                            placeholder="Введите значение"
                        />
                        <ErrorMessage name={item.label} component="div" className="text-danger" />
                    </div>
                );
            case 'boolean':
                return (
                    <div key={item.label} className="form-group form-check mb-2" >
                        <Field
                            type="checkbox"
                            name={item.label}
                            className="form-check-input"
                            id={item.label}
                        />
                        <label className="form-check-label" htmlFor={item.label}>
                            {item.name}
                        </label>
                        <ErrorMessage name={item.label} component="div" className="text-danger" />
                    </div>
                );

            default:
                return null;
        }
    };
    const sendFormDataIC2 = async (data)=> {
        console.log('Sending form data...');
        const currentUserData = localStorage.getItem('currentUserData');
        let accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            const response = await fetch(base_url + '/individual-registration-card-part2/', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + accessToken,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                if (response.status === 400) {
                    const errorData = await response.json();
                    throw new Error(`Bad Request: ${errorData.message}`);
                } else {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            }

            const result = await response.json();
            console.log('Form submitted successfully:', result);
            fetchPatientIRC2Data();
            handleCloseIC2();

        } catch (error) {
            console.error('Error sending form:', error);
            setErrorShow(true);
        }
    }
    const confirmDelete = async () => {
        const currentUserData = localStorage.getItem('currentUserData');
        let accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');

        console.log(`Confirm delete for patient with ID: ${deleteId}`);
        try {
            const response = await fetch(base_url+`/individual-registration-card-part2/${deleteId}/`, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + accessToken,
                },
                body: JSON.stringify({
                    id: deleteId,
                }),
            });
            if (response.ok) {
                console.log('Patient data deleted successfully');
            } else {
                console.error('Failed to delete patient data');
            }
        } catch (error) {
            console.error('An error occurred while deleting patient data:', error);
        }
        setShowDeleteModal(false);
        fetchPatientIRC2Data();
    };

    const openDeleteModal = (id) => {
        setDeleteId(id);
        setShowDeleteModal(true);
    };

    const openEditModal = (data) => {
        setEditData(data);
        setShowEditModal(true);
    };

    const handleEditSubmit = async (values) => {
        console.log('Form submitted with values:', values);
        const currentUserData = localStorage.getItem('currentUserData');
        let accessToken = localStorage.getItem('accessToken');
        try {
            const response = await fetch(base_url+`/individual-registration-card-part2/${values.id}/`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + accessToken,
                },
                body: JSON.stringify(values),
            });
            if (response.ok) {
                console.log('Patient data updated successfully');
            } else {
                console.error('Failed to update patient data');
            }
        } catch (error) {
            console.error('An error occurred while updating patient data:', error);
        }
        setShowEditModal(false);
        fetchPatientIRC2Data();
    };
    const DeleteModal = ({ show, onHide, confirmDelete }) => (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Удаление записи ИРК2</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Вы уверены что хотите удалить запись?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Отменить</Button>
                <Button variant="danger" onClick={confirmDelete}>Удалить</Button>
            </Modal.Footer>
        </Modal>
    );

    const EditModal = ({ show, onHide, data, onSubmit }) => (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Редактировать ИРК2</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={data || initialValuesIC2}
                validationSchema={validationSchemaIC2}
                onSubmit={(values, { setSubmitting }) => {
                    onSubmit(values);
                    setSubmitting(false);
                }}
            >
                {(formik) => (
                    <Form noValidate onSubmit={formik.handleSubmit}>
                        <Modal.Body>
                            {PatientIC2InputType.map((category, catIndex) => (
                                <div key={catIndex} className="mb-4">
                                    <h6 className="text-primary text-center">{category.category}</h6>
                                    <hr/>
                                    {category.data.map((item) => renderFormFieldIC2(item, formik))}
                                </div>
                            ))}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={onHide}>Закрыть</Button>
                            <Button variant="primary" type="submit" className="btn btn-primary">Отправить</Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );


    return (
        <Container className='p-4 rounded-3 border shadow-lg'>
            <div className='d-flex mb-3  justify-content-between align-content-center'>
                <h4 className=' mt-2'>Список ИРК2</h4>
                {currentUserData.role !== 'patient' && (
                <Button
                    variant="primary"
                    className="m-2"
                    onClick={handleShowIC2}
                >
                    Добавить ИРК2
                </Button>
                )}
            </div>
            <DeleteModal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} confirmDelete={confirmDelete} />
            <EditModal show={showEditModal} onHide={() => setShowEditModal(false)} data={editData} onSubmit={handleEditSubmit} />
       
            <div>
                <div className='overflow-x-auto'>
                {Array.isArray(patientIRC2) && patientIRC2.length > 0 ? (
                 
                        <table className='dynamic_table'>
                            <thead>
                                <tr>
                                    <th className="asd"></th>
                                    
                                    { PatientIC2InputType.map((category, catIndex) => (
                                            <th colSpan={category.data.length} key={catIndex}>{category.category}</th>
                                        )) 
                                    }
                                </tr>
                                <tr>
                                    <th className="asd"></th>
                                    { PatientIC2InputType.map((category, catIndex) => (
                                            <> {category.data.map((item, itemIndex) => (
                                            <th key={itemIndex}>{item.name}</th>
                                            ))}</>
                                        ))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                        
                            { patientIRC2.map((dataInstance, index) => (
                                <tr key={index}>
                                {PatientIC2InputType.map((category, catIndex) => (
                                    <>
                                    {category.data.map((item,itemIndex) => (
                                        <>
                                        {item.label === 'weight' ? (
                                            <td className="asd" key={itemIndex}>{format(new Date(dataInstance.created_at), 'dd/MM/yyyy')}</td>
                                        ) : null}
                                        <td key={itemIndex}>
                                            { 
                                            dataInstance[item.label] === null || dataInstance[item.label].length === 0
                                            ? "Нет данных" 
                                            : typeof dataInstance[item.label] === 'boolean'
                                                ? dataInstance[item.label]
                                                    ? 'Есть' 
                                                    : 'Нет'
                                                : translateArrayValues2(item.label, dataInstance[item.label])
                                            }
                                        </td>
                                        
                                        </>
                                    ))}
                                    </>
                                ))}
                                </tr>      
                            ))}
                            </tbody>
                        </table>
                   
                ):null}
                </div>
            
                <Row>
                    <Accordion defaultActiveKey="0" alwaysOpen>
                        {Array.isArray(patientIRC2) && patientIRC2.length > 0 ? (
                            patientIRC2.map((dataInstance, index) => (
                                <Accordion.Item eventKey={String(index)} key={index}>
                                    <Accordion.Header>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <span>Запись ИРК2</span>
                                            <span className='mx-2'>{format(new Date(dataInstance.created_at), 'dd/MM/yyyy')}</span>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        
                                        <div className="d-flex align-items-center justify-content-between mb-4">
                                            <h4 className="text-center">Данные пациента</h4>
                                            {currentUserData.role !== 'patient' && (
                                                <div className="text-end">
                                                    <button className="btn btn-primary me-2"
                                                            onClick={() => openEditModal(dataInstance)}>Редактировать
                                                    </button>
                                                    <button className="btn btn-danger"
                                                            onClick={() => openDeleteModal(dataInstance.id)}>Удалить
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                        




                                        {PatientIC2InputType.map((category, catIndex) => (
                                            <div key={catIndex} className="mb-4">
                                                <h6 className="text-primary text-center">{category.category}</h6>
                                                <hr/>
                                                <Row>
                                                    {category.data.map((item) => (
                                                    
                                                        <Col md={6} className="pb-3 mb-3 border-bottom" key={item.label}>
                                                            {item.label === 'medication_for_he' ? (
                                                                <>
                                                                    <strong className="text-dark">{item.name}: </strong>
                                                                    <span className="d-block">
                                                                        {!translateArrayValues('medication_for_he', dataInstance[item.label]).join(', ') ?
                                                                             "Нет данных" : translateArrayValues('medication_for_he', dataInstance[item.label]).join(', ')}
                                                                        </span>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <strong className="text-dark">{item.name}: </strong>
                                                                    <span className="d-block">
                                                                        {
                                                                        dataInstance[item.label] === null || dataInstance[item.label].length === 0
                                                                        ? "Нет данных" 
                                                                            : typeof dataInstance[item.label] === 'boolean'
                                                                            ? dataInstance[item.label]
                                                                                ? 'Есть'
                                                                                : 'Нет'
                                                                            : translateArrayValues2(item.label, dataInstance[item.label]) === null || translateArrayValues2(item.label, dataInstance[item.label]).length === 0?
                                                                             "Нет данных" : translateArrayValues2(item.label, dataInstance[item.label])
                                                                        }
                                                                    </span>
                                                                </>
                                                            )}
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </div>
                                        ))}
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))
                        ) : (
                            <p>Данных по ИРК2 пациента нет</p>
                        )}
                    </Accordion>
                </Row>
            </div>
            <Modal
                show={showIC2}
                onHide={handleCloseIC2}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Добавить ИРК2</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={initialValuesIC2}
                    validationSchema={validationSchemaIC2}
                    onSubmit={(values, { setSubmitting }) => {
                        handleSubmitIC2(values);
                        console.log(values);
                    }}
                >
                    {(formik) => (
                        <Form noValidate onSubmit={formik.handleSubmit}>
                            <Modal.Body>
                                {PatientIC2InputType.map((category, catIndex) => (
                                    <div key={catIndex} className="mb-4">
                                        <h6 className="text-primary text-center">{category.category}</h6>
                                        <hr/>
                                        {category.data.map((item) => renderFormFieldIC2(item, formik))}
                                    </div>
                                ))}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleCloseIC2}>
                                    Закрыть
                                </Button>
                                <Button variant="primary" type="submit" className="btn btn-primary">
                                    Отправить
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>
        </Container>
    )
}
export default TabIC2;