export const PatientIC2InputType = [
    {
        category: "Лабораторные исследования ",
        data: [
            {label: "weight", name: "Вес, кг", type: "number"},
            {label: "platelet_count", name: "Количество тромбоцитов в крови, тыс/мкл", type: "number"},
            {label: "hemoglobin_level", name: "Уровень гемоглобина в крови, г/л", type: "number"},
            {label: "alt_value", name: "АЛТ  (абсолютное значение, ЕД/л или мкмоль/л)", type: "number"},
            {label: "alt_unit", name: "Единица измерения АЛТ", type: "input"},
            {label: "ast_value", name: "АСТ  (абсолютное значение, ЕД/л или мкмоль/л)", type: "number"},
            {label: "ast_unit", name: "Единица измерения АСТ", type: "input"},
            {label: "bilirubin", name: "Билирубин, мкмоль/л", type: "number"},
            {label: "creatinine", name: "Креатинин, мкмоль/л", type: "number"},
            {label: "inr", name: "МНО", type: "number"},
            {label: "albumin", name: "Альбумин, г/л", type: "number"},
            {label: "sodium", name: "Na+, ммоль/л", type: "number"},
            {label: "potassium", name: "K+, ммоль/л", type: "number"}
        ]
    },
    {
        category: "Стадия заболевания / Фиброз/ Шкалы",
        data: [
            {label: "encephalopathy_type", name: "Тип энцефалопатии", type: "select"},
            {label: "encephalopathy_degree", name: "Степень энцефалопатии", type: "select"},
            {label: "encephalopathy_course", name: "Течение энцефалопатии", type: "select"},
            {label: "presence_of_provoking_factors", name: "Наличие провоцирующих факторов", type: "boolean"}

        ]
    },
    {
        category: "АНАМНЕЗ ПАЦИЕНТА",
        data: [
            {label: "planned_hospitalization_with_liver_disease", name: "Был/а ли ПЛАНОВО госпитализирован/а с заболеваниями печени за последний год?", type: "boolean"},
            {label: "planned_hospitalization_without_liver_disease", name: "Был/а ли ПЛАНОВО госпитализирован/а без заболеваний печени за последний год? ", type: "boolean"},
            {label: "emergency_hospitalization_with_liver_disease", name: "Был/а ли ЭКСТРЕННО госпитализирован/а  с заболеваниями печени за последний год?", type: "boolean"},
            {label: "emergency_hospitalization_without_liver_disease", name: "Был/а ли ЭКСТРЕННО госпитализирован/а  без заболеваний  печени за последний год?", type: "boolean"},
            {label: "had_injuries_in_last_year", name: "Получал/а ли травмы за последний год?", type: "boolean"},

        ]
    },

    {
        category: "Провоцирующие факторы",
        data: [
           {label: "had_gib_in_last_year", name: "ЖКК за последний год?", type: "boolean"},
            {label: "infectious_diseases_last_year", name: "Переносил/а ли инфекционные заболевания за последний год?", type: "select"},
            {label: "stool_character", name: "Характер стула", type: "select"},
            {label: "dehydration", name: "Дегидратация", type: "select"},
            {label: "portosystemic_shunting", name: "Портосистемное шунтирование", type: "select"},
            {label: "thrombosis", name: "Тромбоз", type: "select"},
            {label: "medications", name: "ЛС", type: "select"},
            {label: "renal_insufficiency", name: "Почечная недостаточноть (ОПП, ХБП, ГРС)", type: "boolean"},
            {label: "harmful_habits", name: "Вредные привычки", type: "select"},
        ]
    },
    {
        category: "Приверженность",
        data: [

            {label: "adherence_to_treatment", name: "Приверженность к лечению по ЦП", type: "boolean"},
            {label: "medication_for_he", name: "Список принимаемых ЛС по ПЭ", type: "multiselect"},
            {label: "current_medications", name: "Лекарственные препараты,принимаемые на момент осмотра", type: "input"}
        ]
    },
    {
        category: "Качество жизни ",
        data: [
            {label: "quality_of_life", name: "Как вы бы оценили состояние вашего здоровья?", type: "select"},
            {label: "health_status_comparison", name: "Как бы вы оценили свое здоровье сейчас по сравнению с тем, что было год назад? ", type: "select"}
        ]
    },
    {
        category: "Ограничивает ли Вас состояние Вашего здоровья в настоящее время в выполнени перечисленных ниже физических нагрузок?",
        data: [
            {label: "heavy_physical_activity", name: "Тяжелые физические нагрузки, такие как бег, поднятие тяжестей, занятие силовыми видами спорта", type: "select"},
            {label: "moderate_physical_activity", name: "Умеренные физические нагрузки, такие как передвинуть стол, поработать с пылесосом, собирать грибы или ягоды", type: "select"},
            {label: "carrying_groceries", name: "Поднять или нести сумку с продуктами", type: "select"},
            {label: "climbing_several_flights", name: "Подняться пешком по лестнице на несколько пролетов", type: "select"},
            {label: "climbing_one_flight", name: "Подняться пешком по лестнице на один пролет", type: "select"},
            {label: "bending_kneeling_squatting", name: "Наклониться, встать на колени, присесть на корточки", type: "select"},
            {label: "walking_more_than_1km", name: "Пройти расстояние более одного километра", type: "select"},
            {label: "walking_few_blocks", name: "Пройти расстояние в несколько кварталов", type: "select"},
            {label: "walking_one_block", name: "Пройти расстояние в один квартал", type: "select"},
            {label: "washing_dressing_independently", name: "Самостоятельно вымыться, одеться", type: "select"}
        ]
    },
    {
        category: "Бывало ли за последние 4 недели, что Ваше физическое состояние вызывало затруднения в Вашей работе или другой обычной повседневной деятельности,вследствие чего",
        data: [
            {label: "reduced_time_spent", name: "Пришлось сократить количество времени, затрачиваемого на работу или другие дела", type: "boolean"},
            {label: "did_less_than_wanted", name: "Выполнили ли меньше, чем хотели", type: "boolean"},
            {label: "limited_in_specific_activity", name: "Вы были ограничены в выполнении какого-либо определенного вида работы или другой деятельности", type: "boolean"},
            {label: "had_difficulties_performing_tasks", name: "Были трудности при выполнении своей работы или других дел (например, они потребовали дополнительных усилий)", type: "boolean"},
           ]
    },
    {
        category: "Бывало ли за последние 4 недели, что Ваше эмоциональное состояние вызывало затруднения в Вашей работе или другой обычной повседневной деятельности,вследствие чего",
        data: [
            {label: "reduced_time_spent_emotional", name: "Пришлось сократить количество времени, затрачиваемого на работу или другие дела", type: "boolean"},
            {label: "did_less_than_wanted_emotional", name: "Выполнили ли меньше, чем хотели", type: "boolean"},
            {label: "less_careful_work", name: "Выполняли свою работу или другие дела не так аккуратно, как обычно", type: "boolean"},
            {label: "social_interaction_limitation", name: "Насколько Ваше физическое или эмоциональное состояние в течении последних 4 недель мешало Вам проводить время с семьей, друзьями, соседями или в коллективе? ", type: "select"},
            {label: "physical_pain_severity", name: "Насколько сильную физическую боль Вы испытывали за последние 4 недели?", type: "select"},
            {label: "pain_limitation_on_work", name: "В какой степени боль в течении последних 4 недель мешала Вам заниматься Вашей нормальной работой, включая работу вне дома и по дому?", type: "select"},
        ]
    },
    {
        category: "Пожалуйста, на каждый вопрос дайте один ответ, который наиболее соответствует Вашим ощущениям. Как часто в течении последних 4 недель",
        data: [
            {label: "felt_energetic", name: "Вы чувствовали себя бодрым(ой)?", type: "select"},
            {label: "felt_nervous", name: "Вы сильно нервничали?", type: "select"},
            {label: "felt_down", name: "Вы чувствовали себя таким(ой) подавленным(ой), что ничто не могло Вас взбодрить? ", type: "select"},
            {label: "felt_calm", name: "Вы чувствовали себя спокойным(ой) и умиротворенным(ой)?", type: "select"},
            {label: "felt_strong", name: "Вы чувствовали себя полным(ой) сил и энергии?", type: "select"},
            {label: "felt_sad", name: "Вы чувствовали себя упавшим(ей) духом и печальным(ой)?", type: "select"},
            {label: "felt_exhausted", name: "Вы чувствовали себя измученным(ой)?", type: "select"},
            {label: "felt_happy", name: "Вы чувствовали себя счастливым(ой)? ", type: "select"},
            {label: "felt_tired", name: "Вы чувствовали себя уставшим(ей)? ", type: "select"},
            {label: "interaction_limitation", name: "Как часто в последние 4 недели Ваше физическое или эмоциональное состояние мешало Вам активно общаться с людьми? Например, навещать родственников, друзей и т.п.", type: "select"}
        ]
    },
    {
        category: "Насколько ВЕРНЫМ или НЕВЕРНЫМ представляется по отношению к Вам каждое из ниже перечисленных утверждений? ",
        data: [
            {label: "prone_to_illness", name: "Мне кажется, что я более склонен к болезням, чем другие", type: "select"},
            {label: "health_not_worse_than_others", name: "Мое здоровье не хуже, чем у большинства моих знакомых", type: "select"},
            {label: "expect_health_decline", name: "Я ожидаю, что мое здоровье ухудшится", type: "select"},
            {label: "have_excellent_health", name: "У меня отличное здоровье", type: "select"}
        ]
    }
];
