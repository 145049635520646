import './adminPage.scss';
import AdminHeader from '../adminHeader/adminHeader';
import AdminMain from '../adminMain/adminMain';
import { Route, Routes, useParams } from 'react-router-dom';
import React, {useContext} from 'react';
import AuthContext from "../../../auth/authContext";
import AdminPageHeader from "../adminHeader/adminPageHeader";


const AdminPage = () => {
    const { adminUsername } = useParams();
    const { refresh, setIsAuthenticated, redirectTo, setRedirectTo, homelink} = useContext(AuthContext);
    const logOutButtonHandle = () => {
        const confirmation = window.confirm("Вы уверены, что хотите выйти из аккаунта?");
        if (!confirmation) {
            return;
        }
        setIsAuthenticated(false);
        setRedirectTo('/login');
        localStorage.removeItem('currentUserData');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
    };


    return (
        <div className='adminPage'>
            <AdminPageHeader homelink={localStorage.getItem('homelink')}/>
            <AdminHeader adminUsername={adminUsername}/>
            <Routes>
                <Route path={`all_patients/*`}
                       element={<AdminMain adminUsername={adminUsername} openedSection={'all_patients'}/>}/>
                <Route path={`all_doctors/*`}
                       element={<AdminMain adminUsername={adminUsername} openedSection={'all_doctors'}/>}/>
                <Route path={`all_admins/*`}
                       element={<AdminMain adminUsername={adminUsername} openedSection={'all_admins'}/>}/>
            </Routes>
        </div>
    );
}

export default AdminPage;
