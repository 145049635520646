import React, { useContext } from 'react';
import PageContext from '../../contexts/pageContext';
import Pagination from 'react-bootstrap/Pagination';

const DIV_STYLE = {
    backgroundColor: '#BFDBF7',
    color: '#3E70A1',
    borderRadius: '5px',
};

const generatePaginationItems = (pages, activePage, onPageChange) => {
    let items = [];
    for (let number = 1; number <= pages; number++) {
        items.push(
            <Pagination.Item
                linkStyle={DIV_STYLE}
                key={number}
                active={number === activePage}
                onClick={() => onPageChange(number)}
            >
                {number}
            </Pagination.Item>
        );
    }
    return items;
};

const PaginationComponent = ({ currentPage, totalPages }) => {
    const { handlePageChange } = useContext(PageContext);
    const paginationItems = generatePaginationItems(totalPages, currentPage, handlePageChange);

    return (
        <div className="pageSelectorMenu">
            <div>
                <Pagination>{paginationItems}</Pagination>
                <br />
            </div>
        </div>
    );
};

export default PaginationComponent;