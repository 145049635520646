export const PaperTestFields=[
    {label: "test_a_time", name: "Тест  связывания  чисел  А (в секундах)  ", type: "number"},
    {label: "test_a_errors", name: "Наличие ошибок ", type: "boolean"},
    {label: "test_b_time", name: "Тест  связывания  чисел  Б (в секундах)  ", type: "number"},
    {label: "test_b_errors", name: "Наличие ошибок ", type: "boolean"},
    {label: "number_symbol_test_correct_cells", name: "Тест чисел и символов (количество верно заполненных ячеек) ", type: "number"},
    {label: "number_symbol_test_errors", name: "Наличие ошибок", type: "boolean"},
    {label: "serial_dot_test_time", name: "Серийный тест точек (в секундах) ", type: "number"},
    {label: "serial_dot_test_errors", name: "Наличие ошибок ", type: "boolean"},
    {label: "line_test_time", name: "Тест линий (в секундах)", type: "number"},
    {label: "line_test_boundary_touches", name: "Количество касаний границы ", type: "number"},
    {label: "line_test_boundary_exits", name: "Количество выходов за границы", type: "number"},
    {label: "psychometric_index_pe", name: "Психометрический индекс ПЭ", type: "number"},
    ]