import React, { useContext, useEffect, useState, useMemo, useRef } from 'react';
import AuthContext from '../../../../auth/authContext';
import {Tab,Tabs,Container,Row ,Col} from 'react-bootstrap';
import TabIC1 from "../tabs/tabPatientIC1/tabIC1";
import TabIC2 from "../tabs/tabPatientIC2/tabIC2";
import PaperTest from "../tabs/paperTest/paperTest";
import DigitalTests from "../tabs/digitalTests/digitalTests";
import InBody from "../tabs/inBody/inBody";
import { array, object } from 'yup';
import { Global } from '@emotion/react';
import * as XLSX from 'xlsx';
import {FormSelectionIC2Options} from "../fieldsPatientsData/formSelectionIC2Options";
import {PatientIC2InputType} from "../fieldsPatientsData/patientIC2InputType";
import {PaperTestFields} from "../fieldsPatientsData/paperTestFields";
import {OnlineTestFields} from "../fieldsPatientsData/onlineTestFields";
import {OnlineTestSelect} from "../fieldsPatientsData/onlineTestSelect";
import {InBodyField} from "../fieldsPatientsData/inBodyField";
import { formSelectOptions } from '../fieldsPatientsData/formSelectOptions';
import { PatientInputType } from "../fieldsPatientsData/patientInputType";
import { format, toDate } from 'date-fns';

let globalArrayData = [];
const PatientMain = ({patientID}) => {
    const [patientIRC2, setPatientIRC2] = useState(false);
    const [patientIRC, setPatientIRC] = useState(false);
    const [patientOnlineTest, setPatientOnlineTest] = useState(false);
    const [patientInBody, setPatientInBody] = useState(false);
    const [patientPaperTest, setPatientPaperTest] = useState(false);
    const [key, setKey] = useState('IC1');
    const {refresh, setIsAuthenticated, redirectTo, setRedirectTo } = useContext(AuthContext);
    const base_url = process.env.REACT_APP_BASE_URL;
    const [dataLoading, setDataLoading] = useState(true);
    const [patientData, setPatientData] = useState([]);
    const [isLoggedOut, setIsLoggedOut] = useState(false);
    const currentUserData = localStorage.getItem('currentUserData');
    let accessToken = localStorage.getItem('accessToken');
    const refreshToken = localStorage.getItem('refreshToken');

    

    const tableRef = useRef();
    const handleLogout = () => {
        setIsAuthenticated(false);
        setRedirectTo('/login');
        localStorage.removeItem('currentUserData');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        setIsLoggedOut(true);
    };


    const fetchPatientData = async () => {
        if (!currentUserData) {
            handleLogout();
            return;
        }

        if (!accessToken && refreshToken) {
            try {
                await refresh();
                accessToken = localStorage.getItem('accessToken');
            } catch (error) {
                handleLogout();
                return;
            }
        }

        try {
            let response = await fetch(base_url + `/patients/${patientID}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                }
            });

            if (response.status === 401) {
                try {
                    await refresh();
                    accessToken = localStorage.getItem('accessToken');
                    response = await fetch(base_url + `/patients/${patientID}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + accessToken
                        }
                    });
                } catch (error) {
                    handleLogout();
                    return;
                }
            }

            const patientData = await response.json();

            setPatientData(patientData);
            //console.log(patientData);
            setDataLoading(false);

        } catch (error) {
            console.log("An error occurred while trying to fetch the patient's data.");
            setDataLoading(false);
        }


    };

    useEffect(() => {
        if (!isLoggedOut) {
            fetchPatientData();
        }
    }, [isLoggedOut]);


    
    const fetchPatientAllData = async () => {
        const structuredData = {}; // Здесь собираются данные
        const currentUserData = localStorage.getItem('currentUserData');
        let accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        setDataLoading(true);
        if (!currentUserData) {
            handleLogout();
            return null; // Возвращаем null, если данных нет
        }
    
        const ensureAccessToken = async () => {
            if (!accessToken && refreshToken) {
                try {
                    await refresh();
                    accessToken = localStorage.getItem('accessToken');
                } catch (error) {
                    handleLogout();
                    throw new Error("Token refresh failed");
                }
            }
        };
    
        await ensureAccessToken();
    
        const fetchData = async (endpoint, key) => {
            try {
                let response = await fetch(`${base_url}${endpoint}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`,
                    },
                });
    
                if (response.status === 401) {
                    await refresh();
                    accessToken = localStorage.getItem('accessToken');
                    response = await fetch(`${base_url}${endpoint}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${accessToken}`,
                        },
                    });
                }
    
                const data = await response.json();
                if(Object.keys(data).length > 0) {
                    structuredData[key] = data; // Добавляем данные в объект
                    //console.log("object : ", data);
                }
            } catch (error) {
                console.log(`Error fetching data from ${endpoint}:`, error);
                throw error;
            }
        };
    
        try {
            setDataLoading(true);
    
            await Promise.all([
                fetchData(`/individual-registration-card-part1/patient/${patientID}`, 'patientIRC'),
                fetchData(`/individual-registration-card-part2/patient/${patientID}`, 'patientIRC2'),
                fetchData(`/paper-tests/patient/${patientID}`, 'paperTests'),
                fetchData(`/in-body-analysis/patient/${patientID}`, 'inBodyAnalysis'),
                fetchData(`/online-tests/patient/${patientID}`, 'onlineTests'),
                //fetchData(`/individual-registration-card-part1/patients`, 'patientIRC'),
            ]);
    
            //onsole.log("Structured Data:", structuredData);
            globalArrayData = structuredData;
        } catch (error) {
            console.log("An error occurred while fetching patient data:", error);
            return null;
        } finally {
            setDataLoading(false);
        }
    };
    

    useEffect(() => {
        if (patientID) {
            fetchPatientAllData();
        }

    },[patientID]);
    
    

    if (dataLoading) {
        return <div className='loader'></div>;
    }


    const translateArrayValuesIRC2 = (key, value) => {
        if (FormSelectionIC2Options[key]) {
            const option = FormSelectionIC2Options[key].find((item) => item.label === value);
            return option ? option.translatedLabel : value;
        }
        return value;
    };
    const translateArrayValuesOnlineTests = (key, value) => {
        if (OnlineTestSelect[key]) {
            const option = OnlineTestSelect[key].find((item) => item.label === value);
            return option ? option.translatedLabel : value;
        }
        return value;
    };
    const translateKeyPaper = (key) => {
        const translation = PaperTestFields.find((item) => item.label === key);
        return translation ? translation.name : key;
    };
    const translateKeyOnline = (key) => {
        const translation = OnlineTestFields.find((item) => item.label === key);
        return translation ? translation.name : key;
    };
    const translateKeyInBody = (key) => {
        const translation = InBodyField.find((item) => item.label === key);
        return translation ? translation.name : key;
    };
    const translateValueIRC1 = (field, value) => {
        const fieldTranslations = formSelectOptions[field];
        if (!fieldTranslations) return value;
        const foundTranslation = fieldTranslations.find((item) => item.label === value);
        return foundTranslation ? foundTranslation.translatedLabel : value;
    };
    const translateArrayValuesIRC1 = (field, values) => {
        return values.map((value) => translateValueIRC1(field, value));
    };
    const headersMapping = {
        created_at:"Дата создания",
        ethnicity: "Этническая принадлежность",
        region: "Регион (Казахстан)",
        height: "Рост, см",
        education_level: "Уровень образования",
        marital_status: "Семейное положение",
        job_type: "Тип работы",
        drives_vehicle: "Водит транспортное средство",
        involved_in_car_accident: "Был ли вовлечен в автомобильную аварию",
        liver_conditions: "Состояния печени",
        liver_disease_duration_years: "Длительность заболевания печени (в годах)",
        blood_ammonia_test_result: "Результат теста на аммиак в крови",
        liver_elastography_result: "Результат эластографии печени",
        spleen_elastography_result: "Результат эластографии селезенки",
        varices_degree: "Степень варикозного расширения",
        red_signs_varices: "Наличие красных знаков на варикозных венах",
        ascites_status: "Статус асцита",
        comorbidities: "Сопутствующие заболевания",
    };
    
    const changeKeysAndValues = (data) => {
        const tempArray = {};
        // Создание сопоставления ключей для patientIRC2
        PatientIC2InputType.forEach((category) => {
          category.data.forEach((dataCat) => {
            tempArray[dataCat.label] = dataCat.name;
          });
        });
      
        // Функция для обработки значения
        const processValue = (key, value, translateFn) => {
          if (typeof value === 'boolean') {
            return value ? 'Есть' : 'Нет';
          }
          if (typeof value === 'object' && Array.isArray(value)) {
            return value.map(item => translateFn(key, item)).join(', ');
          }
          if (key === 'created_at') {
            return format(new Date(value), 'dd.MM.yyyy hh:mm:ss');
          }
          return translateFn(key, value);
        };
      
        // Основная обработка данных
        Object.keys(data).forEach((key) => {
          if (Array.isArray(data[key])) {
            data[key] = data[key].map((item) => {
              const newItem = {};
      
              Object.entries(item).forEach(([innerKey, innerValue]) => {
                innerValue = innerValue === null || innerValue.length === 0 ? "Нет данных" : innerValue;
                console.log("asd");
                switch (key) {
                  case 'patientIRC':
                    if (innerKey === 'created_at') {
                        newItem['Дата создания'] = format(new Date(innerValue), 'dd.MM.yyyy hh:mm:ss');
                      }else{
                        newItem[headersMapping[innerKey] || innerKey] = processValue(innerKey, innerValue, translateValueIRC1);}
                    break;
      
                  case 'patientIRC2':
                    
                    if (innerKey === 'created_at') {
                      newItem['Дата создания'] = format(new Date(innerValue), 'dd.MM.yyyy hh:mm:ss');
                    }else{newItem[tempArray[innerKey] || innerKey] = processValue(innerKey, innerValue, translateArrayValuesIRC2);}
                    break;
      
                  case 'paperTests':
                    if (innerKey === 'created_at') {
                        newItem['Дата создания'] = format(new Date(innerValue), 'dd.MM.yyyy hh:mm:ss');
                      }else{
                    newItem[translateKeyPaper(innerKey)] = processValue(innerKey, innerValue, translateArrayValuesIRC2);}
                    break;
      
                  case 'onlineTests':
                    if (innerKey === 'created_at') {
                        newItem['Дата создания'] = format(new Date(innerValue), 'dd.MM.yyyy hh:mm:ss');
                      }else{
                    newItem[translateKeyOnline(innerKey)] = processValue(innerKey, innerValue, translateArrayValuesOnlineTests);}
                    break;
      
                  case 'inBodyAnalysis':
                    if (innerKey === 'created_at') {
                        newItem['Дата создания'] = format(new Date(innerValue), 'dd.MM.yyyy hh:mm:ss');
                      }else{
                    newItem[translateKeyInBody(innerKey)] = processValue(innerKey, innerValue, (k, v) => v);}
                    break;
      
                  default:
                    newItem[innerKey] = innerValue;
                }
              });

              const reorderedObject = {
                'Дата создания': newItem['Дата создания'], // Переносим ключ "a" в начало
                ...newItem,   // Добавляем остальные ключи
              };
              return reorderedObject;
            });
          }
        });
      
        // Добавляем персональные данные
    
        patientData['Лечащий доктор'] = patientData.doctor !== null ? patientData.doctor.surname + " " + patientData.doctor.name + " " + patientData.doctor.patronymic : "Пусто";
        data['personalData'] = patientData;
      
        return data;
      };

    
      

    const exportToExcel = (dataObject) => {
            const updatedData = changeKeysAndValues(dataObject);
            const workbook = XLSX.utils.book_new();
            // Проходим по ключам объекта и добавляем каждый как отдельный лист
            Object.entries(dataObject).forEach(([sheetName, sheetData]) => {
                let worksheet;
    
                // Если данные массив, создаем таблицу
                if (Array.isArray(sheetData)) {
                    worksheet = XLSX.utils.json_to_sheet(sheetData);
                } else {
                    // Если данные объект, преобразуем его в массив
                    worksheet = XLSX.utils.json_to_sheet([sheetData]);
                }
    
                XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
            });
    
            // Скачиваем файл Excel
            XLSX.writeFile(workbook, 'exported_data_'+dataObject.personalData.name + '_'+ dataObject.personalData.surname +'.xlsx');
    };
    

    return (
        <>
        {console.log("mass : ",changeKeysAndValues(globalArrayData))}
        <div className='patientMain'>
            <Container fluid="lg">
                <div className='row p-4 align-items-center rounded-3 border shadow-lg'>
                    <Row className="mb-3">
                        <h5>{patientData.surname} {patientData.name} {patientData.patronymic}</h5>
                    </Row>
                    <hr/>
                    <Row>
                        <Col className="pb-3 mb-3 border-bottom">
                            <strong className="text-dark">ИИН</strong>
                            <span className="d-block"> {patientData.IIN}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="pb-3 mb-3 border-bottom">
                            <strong className="text-dark">Возраст</strong>
                            <span className="d-block">{patientData.age}</span> {/* Replace with actual data */}
                        </Col>
                        <Col className="pb-3 mb-3 border-bottom">
                            <strong className="text-dark">Пол</strong>
                            <span className="d-block"> {patientData.gender}</span>
                        </Col>
                    </Row>
                    {patientData && patientData.doctor ? (
                    <Row>
                        <Col className="pb-3 mb-3 border-bottom">
                            <strong className="text-dark">Лечащий доктор</strong>
                            <span className="d-block">{patientData.doctor.surname} {patientData.doctor.name} {patientData.doctor.patronymic}</span> {/* Replace with actual data */}
                        </Col>
                        <Col className="pb-3 mb-3 border-bottom">
                            <strong className="text-dark">Квалификация</strong>
                            <span className="d-block"> {patientData.doctor.qualification.name}</span>
                        </Col>
                    </Row>
                    ) : null}
                    <button className='btn btn-dark px-3' onClick={() => exportToExcel(globalArrayData)}>Скачать таблицу</button>
                </div>
                
            </Container>
            
            <Container fluid='lg'>
            
                <Tabs
                    id="controlled-tab-example"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    className="my-3"
                >
                    <Tab eventKey="IC1" title="ИРК1">
                        <TabIC1 patientID={patientID}/>
                    </Tab>
                    <Tab eventKey="IC2" title="ИРК2">
                        <TabIC2 patientID={patientID}/>
                    </Tab>
                    <Tab eventKey="paperTests" title="Тесты бумажно">
                       <PaperTest patientID={patientID}/>
                    </Tab>
                    <Tab eventKey="digitalTests" title="Тесты электронные">
                        <DigitalTests patientID={patientID}/>
                    </Tab>
                    <Tab eventKey="inBody" title="InBody">
                        <InBody patientID={patientID}/>
                    </Tab>

                </Tabs>
            </Container>
          </div>
          </>
    );
    
}

export default PatientMain;
