
export const InBodyField = [
    { label: "total_body_water", name: "Общее количество воды (л)", type: "number" },
    { label: "protein", name: "Протеин (кг)", type: "number" },
    { label: "minerals", name: "Минералы (кг)", type: "number" },
    { label: "total_body_fat", name: "Общее содержание жира (кг)", type: "number" },
    { label: "lean_mass", name: "Тощая масса (кг)", type: "number" },
    { label: "fat_free_mass", name: "Безжировая масса (кг)", type: "number" },
    { label: "weight", name: "Вес (кг)", type: "number" },
    { label: "phase_angle", name: "Фазовый угол (градус)", type: "number" },
    { label: "overall_score", name: "Общая оценка (балл)", type: "number" },
    { label: "right_arm_fat", name: "Жир В Правой Руке (кг)", type: "number" },
    { label: "left_arm_fat", name: "Жир В Левой Руке (кг)", type: "number" },
    { label: "trunk_fat", name: "Жир В Туловище (кг)", type: "number" },
    { label: "right_leg_fat", name: "Жир В Правой Ноге (кг)", type: "number" },
    { label: "left_leg_fat", name: "Жир В Левой Ноге (кг)", type: "number" },
    { label: "right_arm_lean_mass", name: "Тощая масса В Правой Руке (кг)", type: "number" },
    { label: "left_arm_lean_mass", name: "Тощая масса В Левой Руке (кг)", type: "number" },
    { label: "trunk_lean_mass", name: "Тощая масса В Туловище (кг)", type: "number" },
    { label: "right_leg_lean_mass", name: "Тощая масса В Правой Ноге (кг)", type: "number" },
    { label: "left_leg_lean_mass", name: "Тощая масса В Левой Ноге (кг)", type: "number" },
    { label: "right_arm_water", name: "Содержание жидкости В Правой Руке (л)", type: "number" },
    { label: "left_arm_water", name: "Содержание жидкости В Левой Руке (л)", type: "number" },
    { label: "trunk_water", name: "Содержание жидкости В Туловище (л)", type: "number" },
    { label: "right_leg_water", name: "Содержание жидкости В Правой Ноге (л)", type: "number" },
    { label: "left_leg_water", name: "Содержание жидкости В Левой Ноге (л)", type: "number" },
    { label: "icw_ecw_ratio", name: "Общее соотношение ВКЖ/ОКЖ", type: "number" },
    { label: "right_arm_icw_ecw_ratio", name: "Соотношение ВКЖ/ОКЖ В Правой Руке", type: "number" },
    { label: "left_arm_icw_ecw_ratio", name: "Соотношение ВКЖ/ОКЖ В Левой Руке", type: "number" },
    { label: "trunk_icw_ecw_ratio", name: "Соотношение ВКЖ/ОКЖ В Туловище", type: "number" },
    { label: "right_leg_icw_ecw_ratio", name: "Соотношение ВКЖ/ОКЖ В Правой Ноге", type: "number" },
    { label: "left_leg_icw_ecw_ratio", name: "Соотношение ВКЖ/ОКЖ В Левой Ноге", type: "number" },
    { label: "intracellular_water", name: "Внутриклеточная вода (л)", type: "number" },
    { label: "extracellular_water", name: "Внеклеточная вода (л)", type: "number" },
    { label: "basal_metabolic_rate", name: "Уровень базального метаболизма (ккал)", type: "number" },
    { label: "waist_hip_ratio", name: "Индекс соотношения талия-бедро", type: "number" },
    { label: "active_cell_mass", name: "Активная масса клеток (кг)", type: "number" },
    { label: "skeletal_muscle_index", name: "ИСМ (кг/м2)", type: "number" },
    { label: "recommended_calories", name: "Рекомендуемый прием калорий (ккал)", type: "number" },
    { label: "waist_circumference", name: "Окружность талии (см)", type: "number" },
    { label: "visceral_fat_area", name: "Область жира внутренних органов (см2)", type: "number" },
    { label: "obesity_degree", name: "Степень ожирения %", type: "number" },
    { label: "arm_circumference", name: "Окружность Руки (см)", type: "number" },
    { label: "arm_circumference_without_fat", name: "Окружность Руки Без Жира (см)", type: "number" },
    { label: "ecw_tbw_ratio", name: "ОКЖ/БЖМ (%)", type: "number" },
    { label: "fat_free_mass_index", name: "Индекс безжировой ткани (кг/м2)", type: "number" },
    { label: "fat_mass_index", name: "Индекс жировой ткани (кг/м2)", type: "number" },
    { label: "bone_mineral_mass", name: "Масса минералов в костях (кг)", type: "number" },
    { label: "skeletal_muscle_mass", name: "Масса скелетной мускулатуры (кг)", type: "number" },
    { label: "body_mass_index", name: "ИМТ (кг/м2)", type: "number" },
    { label: "body_fat_percentage", name: "Процентное содержание жира", type: "number" },
    { label: "ideal_weight", name: "Идеальный вес", type: "number" },
    { label: "weight_control", name: "Контроль веса", type: "number" },
    { label: "fat_control", name: "Контроль жира", type: "number" },
    { label: "muscle_control", name: "Контроль мышц", type: "number" },

];