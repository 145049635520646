
export const formSelectOptions = {
    ethnicity: [
        {value: "1", label: "ASIAN", translatedLabel: "Азиат"},
        {value: "2", label: "EUROPEAN", translatedLabel: "Европеец"},
    ],
    region: [
        {value: "1", label: "ASTANA", translatedLabel: "Астана"},
        {value: "2", label: "ALMATY", translatedLabel: "Алматы"},
        {value: "3", label: "SHYMKENT", translatedLabel: "Шымкент"},
        {value: "4", label: "ABAI_REGION", translatedLabel: "Абайская область"},
        {value: "5", label: "AKMOLINSKAYA_REGION", translatedLabel: "Акмолинская область"},
        {value: "6", label: "AKTOBE_REGION", translatedLabel: "Актюбинская область"},
        {value: "7", label: "ALMATY_REGION", translatedLabel: "Алматинская область"},
        {value: "8", label: "ATYRAU_REGION", translatedLabel: "Атырауская область"},
        {value: "9", label: "EAST_KAZAKHSTAN_REGION", translatedLabel: "Восточно-Казахстанская область"},
        {value: "10", label: "ZHAMBYL_REGION", translatedLabel: "Жамбылская область"},
        {value: "11", label: "ZHETYSU_REGION", translatedLabel: "Жетысуская область"},
        {value: "12", label: "WEST_KAZAKHSTAN_REGION", translatedLabel: "Западно-Казахстанская область"},
        {value: "13", label: "KARAGANDA_REGION", translatedLabel: "Карагандинская область"},
        {value: "14", label: "KOSTANAY_REGION", translatedLabel: "Костанайская область"},
        {value: "15", label: "KYZYLORDA_REGION", translatedLabel: "Кызылординская область"},
        {value: "16", label: "MANGISTAU_REGION", translatedLabel: "Мангистауская область"},
        {value: "17", label: "PAVLODAR_REGION", translatedLabel: "Павлодарская область"},
        {value: "18", label: "NORTH_KAZAKHSTAN_REGION", translatedLabel: "Северо-Казахстанская область"},
        {value: "19", label: "TURKESTAN_REGION", translatedLabel: "Туркестанская область"},
        {value: "20", label: "ULYTAU_REGION", translatedLabel: "Улытауская область"},
    ],
    education_level: [
        {value: "1", label: "INCOMPLETE_SECONDARY", translatedLabel: "Не оконченное среднее"},
        {value: "2", label: "SECONDARY", translatedLabel: "Среднее"},
        {value: "3", label: "HIGHER_EDUCATION", translatedLabel: "Высшее"},
    ],
    marital_status: [
        {value: "1", label: "SINGLE", translatedLabel: "Незамужем/неженат"},
        {value: "2", label: "MARRIED", translatedLabel: "Замужем/женат"},
        {value: "3", label: "DIVORCED", translatedLabel: "Разведен/Разведена"},
        {value: "4", label: "WIDOWED", translatedLabel: "Вдовец/Вдова"},
    ],
    job_type: [
        {value: "1", label: "PRECISE_MECHANISMS", translatedLabel: "С точными механизмами, требующая большей концентрации"},
        {value: "2", label: "OFFICE_WORK", translatedLabel: "Офисная"},
        {value: "3", label: "PHYSICAL_LABOR", translatedLabel: "С активной физ нагрузкой"},
        {value: "4", label: "UNEMPLOYED", translatedLabel: "Не работаю"},
        {value: "5", label: "OTHER", translatedLabel: "Другое"},
    ],
    varices_degree: [
        {value: "0", label: "NO_VARICES", translatedLabel: "Нет"},
        {value: "1", label: "GRADE_1", translatedLabel: "1 степень"},
        {value: "2", label: "GRADE_2", translatedLabel: "2 степень"},
        {value: "3", label: "GRADE_3", translatedLabel: "3 степень"},
        {value: "4", label: "GRADE_4", translatedLabel: "4 степень"},
    ],
    comorbidities: [
        {value: "1", label: "HIV", translatedLabel: "ВИЧ -инфекция"},
        {value: "2", label: "DIABETES", translatedLabel: "Сахарный диабет/гликемия натощак"},
        {value: "3", label: "OBESITY", translatedLabel: "Избыточный вес / ожирение"},
        {value: "4", label: "HEART_DISEASE", translatedLabel: "Ишемическая болезнь сердца"},
        {value: "5", label: "HYPERTENSION", translatedLabel: "АГ"},
        {value: "6", label: "CKD", translatedLabel: "Хроническая болезнь почек"},
        {value: "7", label: "COPD", translatedLabel: "Хроническое обструктивное заболевание легких"},
        {value: "8", label: "OTHER", translatedLabel: "Другое"},
        {value: "0", label: "NO_DATA", translatedLabel: "Нет данных"},
    ],
    ascites_status: [
        {value: "1", label: "NO_ASCITES", translatedLabel: "Нет"},
        {value: "2", label: "CONTROLLED", translatedLabel: "Контролируемый"},
        {value: "3", label: "REFRACTORY", translatedLabel: "Рефрактерный"},
    ],
    liver_conditions: [
        {value: '2', label: 'HCV', translatedLabel: 'ХГС'},
        {value: '3', label: 'HBV', translatedLabel: 'ХГВ'},
        {value: '4', label: 'HDV', translatedLabel: 'ХГД'},
        {value: '5', label: 'NON_ALCOHOLIC_FATTY_LIVER_DISEASE', translatedLabel: 'НАЖБП/МАЖБП'},
        {value: '6', label: 'ALCOHOLIC_STEATOHEPATITIS', translatedLabel: 'Алкогольный стеатогепатит'},
        {value: '7', label: 'AUTOIMMUNE_HEPATITIS', translatedLabel: 'Аутоиммунный гепатит'},
        {value: '8', label: 'PBC', translatedLabel: 'ПБХ'},
        {value: '9', label: 'PSC', translatedLabel: 'ПСХ'},
        {value: '10', label: 'PBC_AUTOIMMUNE_HEPATITIS', translatedLabel: 'ПБХ + АИГ'},
        {value: '11', label: 'PSC_AUTOIMMUNE_HEPATITIS', translatedLabel: 'ПСХ + АИГ'},
        {value: '12', label: 'WILSONS_DISEASE', translatedLabel: 'БВК'},
        {value: '13', label: 'HEMOCHROMATOSIS', translatedLabel: 'Гемохроматоз'},
        {value: '14', label: 'OTHER', translatedLabel: 'Другое'},
        {value: '15', label: 'NO_LIVER_CIRRHOSIS', translatedLabel: 'Нет цирроза печени'}
    ]

};

export default formSelectOptions;
