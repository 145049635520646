import './doctorMain.scss';
import AuthContext from '../../../auth/authContext';
import React, { useContext, useEffect, useState } from 'react';
import PageContext from '../../../contexts/pageContext';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import {useNavigate} from "react-router-dom";
import {Modal} from "react-bootstrap";
import {Formik} from "formik";
import AddPatientModalForm from "../../adminComponents/adminAddPatientMenu/new/adminAddPatientMenu";
import PaginationComponent from '../../pagination/pagination'



const DoctorMain = ({ doctorID }) => {
    const { refresh, setIsAuthenticated, redirectTo, setRedirectTo } = useContext(AuthContext);
    const [dataLoading, setDataLoading] = useState(true);
    const [isLoggedOut, setIsLoggedOut] = useState(false);
    const [doctorData, setDoctorData] = useState({});
    const [patientsData, setPatientsData] = useState([]);
    const [doctorsPatientsData, setDoctorsPatientsData] = useState([])
    const [selectedPatientId, setSelectedPatientId] = useState(null);
    const [isDataEditorMenuOpened, setIsMenuOpened] = useState(false);
    const [isAddPatientMenuOpened, setIsAddPatientMenuOpened] = useState(false);
    const [searchPatientsResults, setSearchPatientsResults] = useState(null);
    const [totalPages, setTotalPages] = useState(0);
    const [isSearchClicked, setIsSearchClicked] = useState(false);
    const [prevTotalPages, setPrevTotalPages] = useState(1);
    const [show, setShow] = useState(false);
    const base_url=process.env.REACT_APP_BASE_URL;
    const navigate = useNavigate();
    const {currentPage, handlePageChange, prevCurrentPage, setPrevCurrentPage } = useContext(PageContext);
    const [searchQuery, setSearchQuery] = useState('');
    

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const handleReload = () => {
        fetchDoctorsPatientsData();
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleSearchClick = () => {
        setPrevCurrentPage(currentPage);
        handlePageChange(1);
        fetchDoctorsPatientsData(searchQuery);
    };

    const handleLogout = () => {
        setIsAuthenticated(false);
        setRedirectTo('/login');
        localStorage.removeItem('currentUserData');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        setIsLoggedOut(true);
    };
    const fetchDoctorData = async () => {
        const currentUserData = localStorage.getItem('currentUserData');
        let accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');

        if (!currentUserData) {
            handleLogout();
            return;
        }

        if (!accessToken && refreshToken) {
            try {
                await refresh();
                accessToken = localStorage.getItem('accessToken');
            } catch (error) {
                handleLogout();
                return;
            }
        }

        try {
            let response = await fetch(base_url + `/doctors/${doctorID}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                }
            });

            if (response.status === 401) {
                try {
                    await refresh();
                    accessToken = localStorage.getItem('accessToken');
                    response = await fetch(base_url + `/doctors/${doctorID}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + accessToken
                        }
                    });
                } catch (error) {
                    handleLogout();
                    return;
                }
            }
            const responseDoctor = await response.json();

            setDoctorData(responseDoctor);
            setDataLoading(false);

        } catch (error) {
            console.log("An error occurred while trying to fetch the patient's data.");
            setDataLoading(false);
        }
    };

    const fetchDoctorsPatientsData = async (search = '') => {
        const currentUserData = localStorage.getItem('currentUserData');
        let accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');

        if (!currentUserData) {
            handleLogout();
            return;
        }
        if (!accessToken) {
            if (refreshToken) {
                await refresh();
                accessToken = localStorage.getItem('accessToken');
            } else {
                handleLogout();
                return;
            }
        }
        try {
            let response = await fetch(base_url+`/patients/doctor/${doctorID}?page=${currentPage}&search=${search}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + accessToken
                    }
                }
            );

            if (response.status === 401) {
                try {
                    await refresh();
                    accessToken = localStorage.getItem('accessToken');
                    response = await fetch(base_url+`/patients/doctor/${doctorID}?page=${currentPage}&search=${search}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + accessToken
                        }
                    });
                    const patientsData = await response.json();
                    setPatientsData(patientsData['data']);
                    setDataLoading(false);
                } catch (error) {
                    handleLogout();
                }
            }
            else {
                const patientsData = await response.json();
                setPatientsData(patientsData.patients);
                setTotalPages(patientsData.total_pages);
                setDataLoading(false);
                console.log("Response Status:", response.status);
                console.log("Patients Data:", patientsData);
            }
        } catch (error) {
            console.log("An error ocured while trying to fetch the patients data.")
        }
    };
    

    useEffect(() => {
        fetchDoctorsPatientsData();
    }, [ currentPage]);

    useEffect(() => {
        if (!isLoggedOut) {
            fetchDoctorData().then(() => {
                fetchDoctorsPatientsData();
            });

        }
    }, [isLoggedOut])
    const handleDoubleClick = (id) => {
        navigate('/patient/'+id+'/patient');
    };
    if (dataLoading) {
        return (
            <div className='loader'></div>
        );
    }

    return (
        <div className='doctorMain mt-5'>
            <Container fluid="lg">
                <h2 className='mb-5 text-center'>Личный кабинет врача</h2>
                <div className='row p-4 align-items-center rounded-3 border shadow-lg'>
                    <Row className="mb-3">
                        <h5>{doctorData.surname} {doctorData.name} {doctorData.patronymic}</h5>
                    </Row>
                    <hr/>
                    <Row>
                        <Col className="pb-3 mb-3 border-bottom">
                            <strong className="text-dark">ИИН</strong>
                            <span className="d-block"> {doctorData.IIN}</span>
                        </Col>
                        <Col className="pb-3 mb-3 border-bottom">
                            <strong className="text-dark">Квалификация</strong>
                            <span className="d-block"> {doctorData.qualification.name}</span>
                        </Col>
                    </Row>
                </div>
            </Container>
            <Container className=' p-4 mt-4 align-items-center rounded-3 border shadow-lg'>
                <Row className="mb-3 justify-content-between align-items-center">
                    <Col xs="auto">
                        <h5 className="mb-0">Список пациентов врача</h5>
                    </Col>
                    <Col xs="auto">
                        <Button variant="primary" onClick={handleShow}>
                            Добавить пациента
                        </Button>
                    </Col>
                </Row>
                <Row className="mb-3 justify-content-between align-items-center">
                    <Col>
                    <div className="col">
                        <div className="input-group bg-body">
                            <input type="search" className="form-control searchBar"
                                   placeholder="Найти пациента по ФИО или ИИН"
                                   aria-label="search" aria-describedby="search"
                                   onChange={handleSearchChange}
                            />
                            <button className="input-group-text searchButton" id="search" onClick={handleSearchClick}>
                            <span className="material-symbols-outlined">Поиск</span>
                        </button>
                        </div>
                    </div>
                    </Col>
                </Row>
                <hr/>
                <Row className="m-1">
                    <table className="table table-striped  table-hover">
                        <thead>
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Фамилия</th>
                            <th scope="col">Имя</th>
                            <th scope="col">Отчество</th>
                            <th scope="col">ИИН</th>
                        </tr>
                        </thead>
                        <tbody>
                        {patientsData && patientsData.length > 0 ? (
                            patientsData.map((patient) => (
                                <React.Fragment key={patient.id}>
                                    <tr onDoubleClick={() => handleDoubleClick(patient.id)}>
                                        <th scope="row">{patient.id}</th>
                                        <td>{patient.surname}</td>
                                        <td>{patient.name}</td>
                                        <td>{patient.patronymic}</td>
                                        <td>{patient.IIN}</td>
                                    </tr>
                                </React.Fragment>
                            ))
                        ) : (
                            <tr>
                                <td>No patients found.</td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </Row>
                <PaginationComponent currentPage={currentPage} totalPages={totalPages}/>
            </Container>
            <AddPatientModalForm show={show} handleClose={handleClose} handleReload={handleReload} hideDoctorList={true} doctorID={doctorID}/>
        </div>
    );
}

export default DoctorMain;
