export const FormSelectionIC2Options = {
    encephalopathy_degree: [
        { label: "HIDDEN_MINIMAL", value: "Hidden Minimal", translatedLabel: "Скрытая ISHEN - Минимальная WHC" },
        { label: "HIDDEN_1", value: "Hidden 1", translatedLabel: "Скрытая ISHEN - 1 WHC " },
        { label: "OBVIOUS_2", value: "Obvious 2", translatedLabel: "Явная ISHEN 2 " },
        { label: "OBVIOUS_3", value: "Obvious 3", translatedLabel: "Явная ISHEN 3 WHC " },
        { label: "OBVIOUS_4", value: "Obvious 4", translatedLabel: "Явная ISHEN 4 WHC " },
        { label: "NO_ENCEPHALOPATHY", value: "No Encephalopathy", translatedLabel: "Нет энцефалопатии " }
    ],
    encephalopathy_type: [
        { label: "ACUTE_A", value: "Acute A", translatedLabel: "А (acute: ПЭ, ассоциированная с острой печеночной недостаточностью)" },
        { label: "BYPASS_B", value: "Bypass B", translatedLabel: "B(bypass: ПЭ, ассоциированная с портосистемным шунтированием)" },
        { label: "CIRRHOSIS_C", value: "Cirrhosis C", translatedLabel: "C(cirrhosis: ПЭ, ассоциированная с ЦП)" },
        { label: "NO_ENCEPHALOPATHY", value: "No Encephalopathy", translatedLabel: "Нет энцефалопатии" }
    ],
    encephalopathy_course: [
        { label: "EPISODIC", value: "Episodic", translatedLabel: "Эпизодическая" },
        { label: "RECURRENT", value: "Recurrent", translatedLabel: "Рецидивирующая" },
        { label: "PERSISTENT", value: "Persistent", translatedLabel: "Персистирующая" }
    ],
    infectious_diseases_last_year: [
        { label: "RESPIRATORY_INFECTION", value: "Respiratory Infection", translatedLabel: "Инфекция органов дыхания" },
        { label: "DIGESTIVE_INFECTION", value: "Digestive Infection", translatedLabel: "Инфекция органов пищеварения" },
        { label: "UROGENITAL_INFECTION", value: "Urogenital Infection", translatedLabel: "Инфекция мочеполовой системы" },
        { label: "OTHER", value: "Other", translatedLabel: "Другое" },
        { label: "NONE", value: "None", translatedLabel: "Нет" }
    ],
    stool_character: [
        { label: "REGULAR", value: "Regular", translatedLabel: "Регулярный (1 раз в 1-2 дня)" },
        { label: "CONSTIPATION", value: "Constipation", translatedLabel: "Запор" },
        { label: "DIARRHEA", value: "Diarrhea", translatedLabel: "Диарея" }
    ],
    dehydration: [
        { label: "UNCONTROLLED_DIURETICS", value: "Uncontrolled Diuretics", translatedLabel: "Назначение диуретиков в неконтролируемых дозировках" },
        { label: "LARGE_VOLUME_PARACENTESIS", value: "Large Volume Paracentesis", translatedLabel: "Парацентез в больших объемах" },
        { label: "NONE", value: "None", translatedLabel: "Нет" },
        { label: "NO_DATA", value: "No Data", translatedLabel: "Нет данных" }
    ],
    portosystemic_shunting: [
        { label: "SURGERY", value: "Surgery", translatedLabel: "Шунтирующие операции" },
        { label: "SPONTANEOUS_GASTROESOPHAGEAL", value: "Spontaneous Gastroesophageal", translatedLabel: "Спонтанные шунты (Гастроэзофагеальные)" },
        { label: "SPONTANEOUS_RETROPERITONEAL", value: "Spontaneous Retroperitoneal", translatedLabel: "Спонтанные шунты (Забрюшинные)" },
        { label: "SPONTANEOUS_ANASTOMOSIS", value: "Spontaneous Anastomosis", translatedLabel: " Спонтанные шунты (Анастомозы между левой ветвью воротной вены и сосудами передней брюшной стенки)" },
        { label: "SPONTANEOUS_RECTAL", value: "Spontaneous Rectal", translatedLabel: " Спонтанные шунты (Между прямокишечным сплетением и нижней полой веной)" },
        { label: "NO_DATA", value: "No Data", translatedLabel: "Нет данных" }
    ],
    thrombosis: [
        { label: "PORTAL_VEIN", value: "Portal Vein", translatedLabel: "Тромбоз воротной вены " },
        { label: "HEPATIC_VEIN", value: "Hepatic Vein", translatedLabel: "Тромбоз печеночных вен" },
        { label: "NONE", value: "None", translatedLabel: "Нет" }
    ],
    medications: [
        { label: "BENZODIAZEPINES", value: "Benzodiazepines", translatedLabel: "Прием бензодиазепин" },
        { label: "OPIOIDS", value: "Opioids", translatedLabel: "Прием опиодов" },
        { label: "PPIS", value: "PPIs", translatedLabel: "ИПП" },
        { label: "NONE", value: "None", translatedLabel: "Нет" }
    ],
    harmful_habits: [
        { label: "SMOKING", value: "Smoking", translatedLabel: "Табакокурение" },
        { label: "ALCOHOL_ABUSE", value: "Alcohol Abuse", translatedLabel: "Злоупотребление алкоголем" },
        { label: "NONE", value: "None", translatedLabel: "Нет" }
    ],
    medication_for_he: [
        { label: "LACTULOSE", value: "Lactulose", translatedLabel: "Лактулоза" },
        { label: "RIFAXIMIN", value: "Rifaximin", translatedLabel: "Рифаксимин" },
        { label: "ORNITHINE_ASPARTATE", value: "Ornithine Aspartate", translatedLabel: "L-орнитин L-аспартат" },
        { label: "NONE", value: "None", translatedLabel: "Ничего из вышеперечисленного" }
    ],
    quality_of_life: [
        { label: "EXCELLENT", value: "Excellent", translatedLabel: "Отлично" },
        { label: "VERY_GOOD", value: "Very Good", translatedLabel: "Очень хорошее" },
        { label: "GOOD", value: "Good", translatedLabel: "Хорошее" },
        { label: "FAIR", value: "Fair", translatedLabel: "Посредственное" },
        { label: "POOR", value: "Poor", translatedLabel: "Плохое" }
    ],
    health_status_comparison: [
        { label: "MUCH_BETTER", value: "Much Better", translatedLabel: "Значительно лучше,чем год назад" },
        { label: "SOMEWHAT_BETTER", value: "Somewhat Better", translatedLabel: "Несколько лечше,чем год назад" },
        { label: "ABOUT_THE_SAME", value: "About the Same", translatedLabel: "Примерно так же, как год назад" },
        { label: "SOMEWHAT_WORSE", value: "Somewhat Worse", translatedLabel: "Несколько хуже, чем год назад" },
        { label: "MUCH_WORSE", value: "Much Worse", translatedLabel: "Гораздо хуже, чем год назад" }
    ],
    physical_limitation: [
        { label: "SIGNIFICANTLY_LIMITS", value: "Significantly Limits", translatedLabel: "Да, значительно ограничивает" },
        { label: "SLIGHTLY_LIMITS", value: "Slightly Limits", translatedLabel: "Да, немного ограничивает" },
        { label: "DOES_NOT_LIMIT", value: "Does Not Limit", translatedLabel: "Нет, совсем не огрничивает" }
    ],
    limitation_degree: [
        { label: "NOT_LIMITING", value: "Not Limiting", translatedLabel: "Совсем не мешало" },
        { label: "SLIGHTLY_LIMITING", value: "Slightly Limiting", translatedLabel: "Немного" },
        { label: "MODERATELY_LIMITING", value: "Moderately Limiting", translatedLabel: "Умеренно" },
        { label: "STRONGLY_LIMITING", value: "Strongly Limiting", translatedLabel: "Сильно" },
        { label: "VERY_STRONGLY_LIMITING", value: "Very Strongly Limiting", translatedLabel: "Очень сильно" }
    ],
    pain_severity: [
        { label: "NONE", value: "None", translatedLabel: "Совсем не испытывал (а)" },
        { label: "VERY_WEAK", value: "Very Weak", translatedLabel: "Очень слабая" },
        { label: "WEAK", value: "Weak", translatedLabel: "Слабая" },
        { label: "MODERATE", value: "Moderate", translatedLabel: "Умеренная" },
        { label: "STRONG", value: "Strong", translatedLabel: "Сильная" },
        { label: "VERY_STRONG", value: "Very Strong", translatedLabel: "Очень сильная" }
    ],
    emotion_state: [
        { label: "ALWAYS", value: "Always", translatedLabel: "Все время" },
        { label: "MOST_OF_THE_TIME", value: "Most of the Time", translatedLabel: "Большую часть времени" },
        { label: "OFTEN", value: "Often", translatedLabel: "Часто" },
        { label: "SOMETIMES", value: "Sometimes", translatedLabel: "Иногда" },
        { label: "RARELY", value: "Rarely", translatedLabel: "Редко" },
        { label: "NEVER", value: "Never", translatedLabel: "Ни разу" }
    ],
    health_perception: [
        { label: "DEFINITELY_TRUE", value: "Definitely True", translatedLabel: "Определенно верно" },
        { label: "MOSTLY_TRUE", value: "Mostly True", translatedLabel: "В основном верно" },
        { label: "DONT_KNOW", value: "Don't Know", translatedLabel: "Не знаю" },
        { label: "MOSTLY_FALSE", value: "Mostly False", translatedLabel: "В основном не верно" },
        { label: "DEFINITELY_FALSE", value: "Definitely False", translatedLabel: "Определенно не верно" }
    ],
    liver_condition: [
        { label: "CIRRHOSIS_AS_AN_OUTCOME", value: "Cirrhosis as an Outcome", translatedLabel: "Цирроз в исходе" },
        { label: "HCV", value: "HCV", translatedLabel: "ХГС" },
        { label: "HBV", value: "HBV", translatedLabel: "ХГВ" },
        { label: "HDV", value: "HDV", translatedLabel: "ХГД" },
        { label: "NON_ALCOHOLIC_FATTY_LIVER_DISEASE", value: "Non-Alcoholic Fatty Liver Disease", translatedLabel: "НАЖБП/МАЖБП" },
        { label: "ALCOHOLIC_STEATOHEPATITIS", value: "Alcoholic Steatohepatitis", translatedLabel: "Алкогольный стеатогепатит" },
        { label: "AUTOIMMUNE_HEPATITIS", value: "Autoimmune Hepatitis", translatedLabel: "Аутоиммунный гепатит" },
        { label: "PBC", value: "PBC", translatedLabel: "ПБХ" },
        { label: "PSC", value: "PSC", translatedLabel: "ПСХ" },
        { label: "PBC_AUTOIMMUNE_HEPATITIS", value: "PBC + Autoimmune Hepatitis", translatedLabel: "ПБХ + АИГ" },
        { label: "PSC_AUTOIMMUNE_HEPATITIS", value: "PSC + Autoimmune Hepatitis", translatedLabel: "ПСХ + АИГ" },
        { label: "WILSONS_DISEASE", value: "Wilson's Disease", translatedLabel: "Болезнь Вильсона-Коновалова" },
        { label: "HEMOCHROMATOSIS", value: "Hemochromatosis", translatedLabel: "Гемохроматоз" },
        { label: "OTHER", value: "Other", translatedLabel: "Другое" },
        { label: "NO_LIVER_CIRRHOSIS", value: "No Liver Cirrhosis", translatedLabel: "Нет цирроза печени" }
    ],
    heavy_physical_activity: [
        { label: "SIGNIFICANTLY_LIMITS", value: "Significantly Limits", translatedLabel: "Да, значительно ограничивает" },
        { label: "SLIGHTLY_LIMITS", value: "Slightly Limits", translatedLabel: "Да, немного ограничивает" },
        { label: "DOES_NOT_LIMIT", value: "Does Not Limit", translatedLabel: "Нет, совсем не огрничивает" }
    ],
    moderate_physical_activity: [
        { label: "SIGNIFICANTLY_LIMITS", value: "Significantly Limits", translatedLabel: "Да, значительно ограничивает" },
        { label: "SLIGHTLY_LIMITS", value: "Slightly Limits", translatedLabel: "Да, немного ограничивает" },
        { label: "DOES_NOT_LIMIT", value: "Does Not Limit", translatedLabel: "Нет, совсем не огрничивает" }
    ],
    carrying_groceries:[
        { label: "SIGNIFICANTLY_LIMITS", value: "Significantly Limits", translatedLabel: "Да, значительно ограничивает" },
        { label: "SLIGHTLY_LIMITS", value: "Slightly Limits", translatedLabel: "Да, немного ограничивает" },
        { label: "DOES_NOT_LIMIT", value: "Does Not Limit", translatedLabel: "Нет, совсем не огрничивает" }
    ],
    climbing_several_flights: [
        { label: "SIGNIFICANTLY_LIMITS", value: "Significantly Limits", translatedLabel: "Да, значительно ограничивает" },
        { label: "SLIGHTLY_LIMITS", value: "Slightly Limits", translatedLabel: "Да, немного ограничивает" },
        { label: "DOES_NOT_LIMIT", value: "Does Not Limit", translatedLabel: "Нет, совсем не огрничивает" }
    ],
    climbing_one_flight: [
        { label: "SIGNIFICANTLY_LIMITS", value: "Significantly Limits", translatedLabel: "Да, значительно ограничивает" },
        { label: "SLIGHTLY_LIMITS", value: "Slightly Limits", translatedLabel: "Да, немного ограничивает" },
        { label: "DOES_NOT_LIMIT", value: "Does Not Limit", translatedLabel: "Нет, совсем не огрничивает" }
    ],
    bending_kneeling_squatting: [
        { label: "SIGNIFICANTLY_LIMITS", value: "Significantly Limits", translatedLabel: "Да, значительно ограничивает" },
        { label: "SLIGHTLY_LIMITS", value: "Slightly Limits", translatedLabel: "Да, немного ограничивает" },
        { label: "DOES_NOT_LIMIT", value: "Does Not Limit", translatedLabel: "Нет, совсем не огрничивает" }
    ],
    walking_more_than_1km: [
        { label: "SIGNIFICANTLY_LIMITS", value: "Significantly Limits", translatedLabel: "Да, значительно ограничивает" },
        { label: "SLIGHTLY_LIMITS", value: "Slightly Limits", translatedLabel: "Да, немного ограничивает" },
        { label: "DOES_NOT_LIMIT", value: "Does Not Limit", translatedLabel: "Нет, совсем не огрничивает" }
    ],
    walking_few_blocks: [
        { label: "SIGNIFICANTLY_LIMITS", value: "Significantly Limits", translatedLabel: "Да, значительно ограничивает" },
        { label: "SLIGHTLY_LIMITS", value: "Slightly Limits", translatedLabel: "Да, немного ограничивает" },
        { label: "DOES_NOT_LIMIT", value: "Does Not Limit", translatedLabel: "Нет, совсем не огрничивает" }
    ],
    walking_one_block: [
        { label: "SIGNIFICANTLY_LIMITS", value: "Significantly Limits", translatedLabel: "Да, значительно ограничивает" },
        { label: "SLIGHTLY_LIMITS", value: "Slightly Limits", translatedLabel: "Да, немного ограничивает" },
        { label: "DOES_NOT_LIMIT", value: "Does Not Limit", translatedLabel: "Нет, совсем не огрничивает" }
    ],
    washing_dressing_independently: [
        { label: "SIGNIFICANTLY_LIMITS", value: "Significantly Limits", translatedLabel: "Да, значительно ограничивает" },
        { label: "SLIGHTLY_LIMITS", value: "Slightly Limits", translatedLabel: "Да, немного ограничивает" },
        { label: "DOES_NOT_LIMIT", value: "Does Not Limit", translatedLabel: "Нет, совсем не огрничивает" }
    ],
    social_interaction_limitation: [
        { label: "NOT_LIMITING", value: "Not Limiting", translatedLabel: "Нет, совсем не огрничивает" },
        { label: "SLIGHTLY_LIMITING", value: "Slightly Limiting", translatedLabel: "Да, немного ограничивает" },
        { label: "MODERATELY_LIMITING", value: "Moderately Limiting", translatedLabel: "Умеренно ограничивает" },
        { label: "STRONGLY_LIMITING", value: "Strongly Limiting", translatedLabel: "Сильно ограничивает" },
        { label: "VERY_STRONGLY_LIMITING", value: "Very Strongly Limiting", translatedLabel: "Очень сильно ограничивает" }
    ],
    pain_limitation_on_work: [
        { label: "NOT_LIMITING", value: "Not Limiting", translatedLabel: "Совсем не мешала" },
        { label: "SLIGHTLY_LIMITING", value: "Slightly Limiting", translatedLabel: "Немного" },
        { label: "MODERATELY_LIMITING", value: "Moderately Limiting", translatedLabel: "Умеренно" },
        { label: "STRONGLY_LIMITING", value: "Strongly Limiting", translatedLabel: "Сильно" },
        { label: "VERY_STRONGLY_LIMITING", value: "Very Strongly Limiting", translatedLabel: "Очень сильно" }
    ],
    felt_energetic: [
        { label: "ALWAYS", value: "Always", translatedLabel: "Все время" },
        { label: "MOST_OF_THE_TIME", value: "Most of the Time", translatedLabel: "Большую часть времени" },
        { label: "OFTEN", value: "Often", translatedLabel: "Часто" },
        { label: "SOMETIMES", value: "Sometimes", translatedLabel: "Иногда" },
        { label: "RARELY", value: "Rarely", translatedLabel: "Редко" },
        { label: "NEVER", value: "Never", translatedLabel: "Ни разу" }
    ],
felt_nervous : [
    { label: "ALWAYS", value: "Always", translatedLabel: "Все время" },
    { label: "MOST_OF_THE_TIME", value: "Most of the Time", translatedLabel: "Большую часть времени" },
    { label: "OFTEN", value: "Often", translatedLabel: "Часто" },
    { label: "SOMETIMES", value: "Sometimes", translatedLabel: "Иногда" },
    { label: "RARELY", value: "Rarely", translatedLabel: "Редко" },
    { label: "NEVER", value: "Never", translatedLabel: "Ни разу" }
],
felt_down : [
    { label: "ALWAYS", value: "Always", translatedLabel: "Все время" },
    { label: "MOST_OF_THE_TIME", value: "Most of the Time", translatedLabel: "Большую часть времени" },
    { label: "OFTEN", value: "Often", translatedLabel: "Часто" },
    { label: "SOMETIMES", value: "Sometimes", translatedLabel: "Иногда" },
    { label: "RARELY", value: "Rarely", translatedLabel: "Редко" },
    { label: "NEVER", value: "Never", translatedLabel: "Ни разу" }
],


felt_calm : [
    { label: "ALWAYS", value: "Always", translatedLabel: "Все время" },
    { label: "MOST_OF_THE_TIME", value: "Most of the Time", translatedLabel: "Большую часть времени" },
    { label: "OFTEN", value: "Often", translatedLabel: "Часто" },
    { label: "SOMETIMES", value: "Sometimes", translatedLabel: "Иногда" },
    { label: "RARELY", value: "Rarely", translatedLabel: "Редко" },
    { label: "NEVER", value: "Never", translatedLabel: "Ни разу" }
],


felt_strong : [
    { label: "ALWAYS", value: "Always", translatedLabel: "Все время" },
    { label: "MOST_OF_THE_TIME", value: "Most of the Time", translatedLabel: "Большую часть времени" },
    { label: "OFTEN", value: "Often", translatedLabel: "Часто" },
    { label: "SOMETIMES", value: "Sometimes", translatedLabel: "Иногда" },
    { label: "RARELY", value: "Rarely", translatedLabel: "Редко" },
    { label: "NEVER", value: "Never", translatedLabel: "Ни разу" }
],


felt_sad : [
    { label: "ALWAYS", value: "Always", translatedLabel: "Все время" },
    { label: "MOST_OF_THE_TIME", value: "Most of the Time", translatedLabel: "Большую часть времени" },
    { label: "OFTEN", value: "Often", translatedLabel: "Часто" },
    { label: "SOMETIMES", value: "Sometimes", translatedLabel: "Иногда" },
    { label: "RARELY", value: "Rarely", translatedLabel: "Редко" },
    { label: "NEVER", value: "Never", translatedLabel: "Ни разу" }
],
felt_exhausted : [
    { label: "ALWAYS", value: "Always", translatedLabel: "Все время" },
    { label: "MOST_OF_THE_TIME", value: "Most of the Time", translatedLabel: "Большую часть времени" },
    { label: "OFTEN", value: "Often", translatedLabel: "Часто" },
    { label: "SOMETIMES", value: "Sometimes", translatedLabel: "Иногда" },
    { label: "RARELY", value: "Rarely", translatedLabel: "Редко" },
    { label: "NEVER", value: "Never", translatedLabel: "Ни разу" }
],
felt_happy : [
    { label: "ALWAYS", value: "Always", translatedLabel: "Все время" },
    { label: "MOST_OF_THE_TIME", value: "Most of the Time", translatedLabel: "Большую часть времени" },
    { label: "OFTEN", value: "Often", translatedLabel: "Часто" },
    { label: "SOMETIMES", value: "Sometimes", translatedLabel: "Иногда" },
    { label: "RARELY", value: "Rarely", translatedLabel: "Редко" },
    { label: "NEVER", value: "Never", translatedLabel: "Ни разу" }
],
felt_tired : [
    { label: "ALWAYS", value: "Always", translatedLabel: "Все время" },
    { label: "MOST_OF_THE_TIME", value: "Most of the Time", translatedLabel: "Большую часть времени" },
    { label: "OFTEN", value: "Often", translatedLabel: "Часто" },
    { label: "SOMETIMES", value: "Sometimes", translatedLabel: "Иногда" },
    { label: "RARELY", value: "Rarely", translatedLabel: "Редко" },
    { label: "NEVER", value: "Never", translatedLabel: "Ни разу" }
],
interaction_limitation : [
    { label: "ALWAYS", value: "Always", translatedLabel: "Все время" },
    { label: "MOST_OF_THE_TIME", value: "Most of the Time", translatedLabel: "Большую часть времени" },
    { label: "OFTEN", value: "Often", translatedLabel: "Часто" },
    { label: "SOMETIMES", value: "Sometimes", translatedLabel: "Иногда" },
    { label: "RARELY", value: "Rarely", translatedLabel: "Редко" },
    { label: "NEVER", value: "Never", translatedLabel: "Ни разу" }
],
    prone_to_illness: [
        { label: "DEFINITELY_TRUE", value: "Definitely True", translatedLabel: "Определенно верно" },
        { label: "MOSTLY_TRUE", value: "Mostly True", translatedLabel: "В основном верно" },
        { label: "DONT_KNOW", value: "Don't Know", translatedLabel: "Не знаю" },
        { label: "MOSTLY_FALSE", value: "Mostly False", translatedLabel: "В основном не верно" },
        { label: "DEFINITELY_FALSE", value: "Definitely False", translatedLabel: "Определенно не верно" }
    ],
    health_not_worse_than_others : [
        { label: "DEFINITELY_TRUE", value: "Definitely True", translatedLabel: "Определенно верно" },
        { label: "MOSTLY_TRUE", value: "Mostly True", translatedLabel: "В основном верно" },
        { label: "DONT_KNOW", value: "Don't Know", translatedLabel: "Не знаю" },
        { label: "MOSTLY_FALSE", value: "Mostly False", translatedLabel: "В основном не верно" },
        { label: "DEFINITELY_FALSE", value: "Definitely False", translatedLabel: "Определенно не верно" }
    ],
    expect_health_decline: [
        { label: "DEFINITELY_TRUE", value: "Definitely True", translatedLabel: "Определенно верно" },
        { label: "MOSTLY_TRUE", value: "Mostly True", translatedLabel: "В основном верно" },
        { label: "DONT_KNOW", value: "Don't Know", translatedLabel: "Не знаю" },
        { label: "MOSTLY_FALSE", value: "Mostly False", translatedLabel: "В основном не верно" },
        { label: "DEFINITELY_FALSE", value: "Definitely False", translatedLabel: "Определенно не верно" }
    ],
    have_excellent_health : [
        { label: "DEFINITELY_TRUE", value: "Definitely True", translatedLabel: "Определенно верно" },
        { label: "MOSTLY_TRUE", value: "Mostly True", translatedLabel: "В основном верно" },
        { label: "DONT_KNOW", value: "Don't Know", translatedLabel: "Не знаю" },
        { label: "MOSTLY_FALSE", value: "Mostly False", translatedLabel: "В основном не верно" },
        { label: "DEFINITELY_FALSE", value: "Definitely False", translatedLabel: "Определенно не верно" }
    ],
    physical_pain_severity: [
        { label: "NONE", value: "None", translatedLabel: "Совсем не испытывал (а)" },
        { label: "VERY_WEAK", value: "Very Weak", translatedLabel: "Очень слабая" },
        { label: "WEAK", value: "Weak", translatedLabel: "Слабая" },
        { label: "MODERATE", value: "Moderate", translatedLabel: "Умеренная" },
        { label: "STRONG", value: "Strong", translatedLabel: "Сильная" },
        { label: "VERY_STRONG", value: "Very Strong", translatedLabel: "Очень сильная" }
    ],


};