
import React, {useContext, useState,useEffect} from "react";
import * as Yup from "yup";
import {ErrorMessage, Field, Formik} from "formik";
import {Modal,Form,Button,Container,Row,Accordion,Col} from "react-bootstrap";
import Select from "react-select";
import AuthContext from "../../../../../auth/authContext";
import {PaperTestFields} from "../../fieldsPatientsData/paperTestFields";
import { format } from 'date-fns';
import {PatientInputType} from "../../fieldsPatientsData/patientInputType";

const PaperTest = ({patientID}) => {
    const base_url = process.env.REACT_APP_BASE_URL;
    const [errorShow, setErrorShow] = useState(false);
    const [patientPaperTest, setPatientPaperTest] = useState(false);
    const [dataLoading, setDataLoading] = useState(true);
    const [show, setShow] = useState(false);
    const {refresh, setIsAuthenticated, redirectTo, setRedirectTo } = useContext(AuthContext);
    const [isLoggedOut, setIsLoggedOut] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [editData, setEditData] = useState(null);
    const [showEditModal, setShowEditModal] =  useState(false);
    const currentUserData = JSON.parse(localStorage.getItem('currentUserData') || '{}');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleLogout = () => {
        setIsAuthenticated(false);
        setRedirectTo('/login');
        localStorage.removeItem('currentUserData');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        setIsLoggedOut(true);
    };
    const translateKey = (key) => {
        const translation = PaperTestFields.find((item) => item.label === key);
        return translation ? translation.name : key;
    };
    const generateValidationSchema = (fields) => {
        const schemaFields = {};
        fields.map((item) => {
            switch (item.type) {
                case 'number':
                    schemaFields[item.label] = Yup.number()
                        .typeError(`${item.name} должно быть числом`)
                        .required(`Поле ${item.name} является обязательным`);
                    break;
                case 'input':
                    schemaFields[item.label] = Yup.string()
                        .required(`Поле ${item.name} является обязательным`);
                    break;
                case 'select':
                    schemaFields[item.label] = Yup.string()
                        .required(`Выберите однo из значений`);
                    break;
                case 'multiselect':
                    schemaFields[item.label] = Yup.array()
                        .of(Yup.string())
                        .min(1, `Выберите хотя бы одно значение из списка`)
                        .required(`Выберите хотя бы одно значение из списка`);
                    break;
                case 'boolean':
                    schemaFields[item.label] = Yup.boolean()
                        .required(`${item.name} является обязательным`);
                    break;
                default:
                    schemaFields[item.label] = Yup.string();
            }
        });

        return Yup.object().shape(schemaFields);
    };

    const validationSchema = generateValidationSchema(PaperTestFields);
    const fetchPatientPaperData = async () => {
        const currentUserData = localStorage.getItem('currentUserData');
        let accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');

        if (!currentUserData) {
            handleLogout();
            return;
        }

        if (!accessToken && refreshToken) {
            try {
                await refresh();
                accessToken = localStorage.getItem('accessToken');
            } catch (error) {
                handleLogout();
                return;
            }
        }

        try {
            let response = await fetch(base_url + `/paper-tests/patient/${patientID}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                }
            });

            if (response.status === 401) {
                try {
                    await refresh();
                    accessToken = localStorage.getItem('accessToken');
                    response = await fetch(base_url + `/paper-tests/patient/${patientID}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + accessToken
                        }
                    });
                } catch (error) {
                    handleLogout();
                    return;
                }
            }

            const patientPaperTest = await response.json();
            setPatientPaperTest(patientPaperTest);
            setDataLoading(false);
        } catch (error) {
            console.log("An error occurred while trying to fetch the patient's data.");
            setDataLoading(false);
        }
    };
    useEffect(() => {
        if (patientID) {
            fetchPatientPaperData();
        }

    },[patientID]);


    const initialValues = PaperTestFields.reduce((values, item) => {
        switch (item.type) {
            case 'number':
                values[item.label] = ''; // Initialize select and number fields with an empty string
                break;
            case 'boolean':
                values[item.label] = false; // Initialize boolean fields with false
                break;
            default:
                values[item.label] = ''; // Default to empty string for any other type
        }
        return values;
    }, {});


    const handleSubmit = (values) => {
        values['patient_id']=patientID;
        console.log('Form Data:', values);
        sendFormData(values);
    }
    if (dataLoading) {
        return <div className='loader'></div>;
    }
    const renderFormField = (item, formik) => {
        const { values, setFieldValue } = formik;
        switch (item.type) {
            case 'number':
                return (
                    <div key={item.label} className="form-group mb-2">
                        <label>{item.name}</label>
                        <Field
                            type="number"
                            name={item.label}
                            className={`form-control ${formik.touched[item.label] && formik.errors[item.label] ? 'is-invalid' : ''}`}
                            placeholder="Введите число"
                            onWheel={(e) => e.target.blur()} // Prevent number change on wheel scroll
                        />
                        <ErrorMessage name={item.label} component="div" className="text-danger" />
                    </div>
                );
            case 'boolean':
                return (
                    <div key={item.label} className="form-group form-check mb-2">
                        <Field
                            type="checkbox"
                            name={item.label}
                            className="form-check-input"
                            id={item.label}
                        />
                        <label className="form-check-label" htmlFor={item.label}>
                            {item.name}
                        </label>
                        <ErrorMessage name={item.label} component="div" className="text-danger" />
                    </div>
                );
            default:
                return null;
        }
    };

    const sendFormData = async (data)=> {
        console.log('Sending form data...');
        const currentUserData = localStorage.getItem('currentUserData');
        let accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            const response = await fetch(base_url + '/paper-tests/', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + accessToken,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                if (response.status === 400) {
                    const errorData = await response.json();
                    throw new Error(`Bad Request: ${errorData.message}`);
                } else {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            }

            const result = await response.json();
            console.log('Form submitted successfully:', result);
            handleClose();
            fetchPatientPaperData();

        } catch (error) {
            console.error('Error sending form:', error);
            setErrorShow(true);
        }
    }
    if (dataLoading) {
        return <div className='loader'></div>;
    }
    const openEditModal = (data) => {
        setEditData(data);
        setShowEditModal(true);
    };
    const handleEditSubmit = async (values) => {
        console.log('Form submitted with values:', values);
        const currentUserData = localStorage.getItem('currentUserData');
        let accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            const response = await fetch(base_url + `/paper-tests/${values.id}`, {
                method: 'PUT',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + accessToken,
                },
                body: JSON.stringify(values),
            });
            if (response.ok) {
                console.log('Patient data updated successfully');
                // Add logic to update the UI after successful update
            } else {
                console.error('Failed to update patient data');
            }
        } catch (error) {
            console.error('An error occurred while updating patient data:', error);
        }
        setShowEditModal(false);
        fetchPatientPaperData();
    };
    const EditModal = ({ show, onHide, data, onSubmit }) => (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Редактировать бумажный тест</Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={data || initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    onSubmit(values);
                    setSubmitting(false);
                }}
            >
                {(formik) => (
                    <Form noValidate onSubmit={formik.handleSubmit}>
                        <Modal.Body>
                            {PaperTestFields.map((item) => renderFormField(item, formik))}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={onHide}>Закрыть</Button>
                            <Button variant="primary" type="submit" className="btn btn-primary">Отправить</Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    );


    const confirmDelete = async () => {
        console.log(`Confirm delete for patient with ID: ${deleteId}`);
        const currentUserData = localStorage.getItem('currentUserData');
        let accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            const response = await fetch(base_url + `/paper-tests/${deleteId}/`, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + accessToken,
                },
            });
            if (response.ok) {
                console.log('Patient data deleted successfully');
                // Add logic to update the UI after successful deletion
            } else {
                console.error('Failed to delete patient data');
            }
        } catch (error) {
            console.error('An error occurred while deleting patient data:', error);
        }
        setShowDeleteModal(false);
        fetchPatientPaperData();
    };

    const openDeleteModal = (id) => {
        setDeleteId(id);
        setShowDeleteModal(true);
    };

    const DeleteModal = ({ show, onHide, confirmDelete }) => (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} size="sm" centered>
            <Modal.Header closeButton>
                <Modal.Title>Удалить запись</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Вы уверены что хотите удалить бумажный тест</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Отменить</Button>
                <Button variant="danger" onClick={confirmDelete}>Удалить</Button>
            </Modal.Footer>
        </Modal>
    );

    return (
        <Container className="p-4  rounded-3 border shadow-lg">
            <div className='d-flex mb-3  justify-content-between align-content-center'>
                <h4 className=' mt-2'>Бумажные тесты</h4>
                {currentUserData.role !== 'patient' && (
                <Button
                    variant="primary"
                    className="m-2"
                    onClick={handleShow}
                >
                    Добавить бумажные тесты
                </Button>
                )}
            </div>
            <DeleteModal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} confirmDelete={confirmDelete} />
            <EditModal show={showEditModal} onHide={() => setShowEditModal(false)} data={editData} onSubmit={handleEditSubmit} />
            <div>
            <div className='overflow-x-auto'>
            {Array.isArray(patientPaperTest) && patientPaperTest.length > 0 ? (
               
                <table className='dynamic_table'>
                    <thead>
                    <tr>
                        {patientPaperTest.map((dataInstance, index) => (
                            index < 1 && (
                            <>
                                <th className="asd"></th>
                                {Object.keys(dataInstance)
                                    .filter((key) => key !== 'patient_id' && key !== 'id' && key !== 'created_at' && key !== 'updated_at')
                                    .map((key) => (
                                        <th className="text-dark" key={key}>{translateKey(key)}</th>
                                ))}   
                           </>
                        )))}
                         </tr>
                        </thead>
                        <tbody>
                            {patientPaperTest.map((dataInstance, index) => (
                                <tr key={index}> 
                                    <td className="asd">{format(new Date(dataInstance['created_at']), 'dd/MM/yyyy')}</td>
                                    {Object.keys(dataInstance)
                                        .filter((key) => key !== 'patient_id' && key !== 'created_at' && key !== 'id' && key !== 'updated_at')
                                            .map((key) => (
                                                <td>{typeof dataInstance[key] === 'boolean' ? dataInstance[key] ? 'Есть' : 'Нет' : dataInstance[key]}</td>
                                        ))}               
                                </tr>
                            ))}
                    </tbody>
                </table>
                
            ):null}
            </div>
            
                <Row>
                    <Accordion defaultActiveKey="0" alwaysOpen>
                        {Array.isArray(patientPaperTest) && patientPaperTest.length > 0 ? (
                            patientPaperTest.map((dataInstance, index) => (
                                <Accordion.Item eventKey={String(index)} key={index}>
                                    <Accordion.Header>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <span>Тест</span>
                                            <span className='mx-2'>{format(new Date(dataInstance.created_at), 'dd/MM/yyyy')}</span>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div key={dataInstance.id}
                                             className="d-flex align-items-center justify-content-between">
                                            <h4 className="mb-0">Данные пациента</h4>
                                            {currentUserData.role !== 'patient' && (

                                                <div>
                                                <button className="btn btn-primary me-2"
                                                        onClick={() => openEditModal(dataInstance)}>Редактировать
                                                </button>
                                                <button className="btn btn-danger"
                                                        onClick={() => openDeleteModal(dataInstance.id)}>Удалить
                                                </button>
                                            </div>
                                                )}
                                        </div>
                                        <hr/>
                                        <div>
                                            <Row>
                                                {Object.keys(dataInstance)
                                                    .filter((key) => key !== 'patient_id' && key !== 'id' && key !== 'created_at' && key !== 'updated_at')
                                                    .map((key) => (
                                                        <Col md={6} className="pb-3 mb-3 border-bottom" key={key}>
                                                            <strong className="text-dark">{translateKey(key)}: </strong>
                                                            <span className="d-block">
                                                                {typeof dataInstance[key] === 'boolean'
                                                                    ? dataInstance[key]
                                                                        ? 'Есть'
                                                                        : 'Нет'
                                                                    : dataInstance[key]}
                                                              </span>
                                                        </Col>
                                                    ))}
                                            </Row>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))
                        ) : (
                            <p>Бумажных тестов нет</p>
                        )}
                    </Accordion>

                </Row>
            </div>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Добавить тест</Modal.Title>
                </Modal.Header>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, {setSubmitting}) => {
                        handleSubmit(values);
                        setSubmitting(false);
                    }}
                >
                    {(formik) => (
                        <Form noValidate onSubmit={formik.handleSubmit}>
                            <Modal.Body>
                                {PaperTestFields.map((item) => renderFormField(item, formik))}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Закрыть
                                </Button>
                                <Button variant="primary" type="submit" className="btn btn-primary">
                                    Отправить
                                </Button>
                            </Modal.Footer>
                        </Form>
                    )}
                </Formik>
            </Modal>

        </Container>

    )

}
export default PaperTest;