import React, {useContext, useEffect, useState} from 'react';
import './patientHeader.scss';
import LogOutIcon from './icons/logoutButtonIcon.png'
import { Link, Navigate, useLocation } from "react-router-dom";
import AuthContext from '../../../auth/authContext';
import AdminPageHeader from "../../adminComponents/adminHeader/adminPageHeader";


const PatientHeader = ({ patientID }) => {
  let location = useLocation();
  const { setIsAuthenticated, redirectTo, setRedirectTo} = useContext(AuthContext);
  const [key, setKey] = useState('home');


  const logOutButtonHandle = () => {
    const confirmation = window.confirm("Вы уверены, что хотите выйти из аккаунта?");
    if (!confirmation) {
      return;
    }

    setIsAuthenticated(false);
    setRedirectTo('/login');
    localStorage.removeItem('currentUserData');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  };

  useEffect(() => {
    if (redirectTo) {
      setRedirectTo(null);
    }
  }, [redirectTo, setRedirectTo]);

  if (redirectTo) {
    return <Navigate to={redirectTo} replace />;
  }

  return (
      <>
        <AdminPageHeader homelink={localStorage.getItem('homelink')}/>
        <div className="header_box">
          <nav className="navigation_box">
            <ul className='navbar'>
              <Link to={`/patient/${patientID}/patient`} className={`navbar__element ${location.pathname === `/patient/${patientID}/account` ? "active" : ""}`}><li>ЛИЧНЫЙ КАБИНЕТ ПАЦИЕНТА</li></Link>
              <Link to={`/patient/${patientID}/tests`} className={`navbar__element ${location.pathname.includes(`/patient/${patientID}/tests`) ? "active" : ""}`}><li>ТЕСТЫ</li></Link>
            </ul>
          </nav>
        </div>
      </>
  );
}

export default PatientHeader;
