import './adminMain.scss';
import AuthContext from '../../../auth/authContext';
import PatientList from "./patientsList/patientList";
import AdminList from "./adminList/adminList";
import DoctorList from "./doctorList/doctorList";
import { Link, Navigate } from "react-router-dom";
import React, { useContext, useEffect, useState } from 'react';



const AdminMain = ({ adminUsername, openedSection }) => {
    const { refresh, setIsAuthenticated, redirectTo, setRedirectTo } = useContext(AuthContext);



    useEffect(() => {
        if (redirectTo) {
            setRedirectTo(null);
        }
    }, [redirectTo, setRedirectTo]);


    if (redirectTo) {
        return <Navigate to={redirectTo} replace />;
    }



    if (openedSection === 'all_patients') {
        return (
            <>
                <PatientList/>
            </>
        );
    } else if (openedSection === 'all_admins') {
        return (
            <>
                <AdminList/>
            </>
        );
    } else if (openedSection === 'all_doctors') {
        return (
            <>
                <DoctorList/>
            </>
        );
    }
}

export default AdminMain;
