import { useContext, useEffect, useRef, useState } from 'react';
import { Modal, Button, Col, Form, Row, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as formik from 'formik';
import * as yup from 'yup';

const AdminAddPatientMenu = ({ show, handleClose, hideDoctorList, doctorID }) => {
    const { Formik } = formik;
    const base_url = process.env.REACT_APP_BASE_URL;
    const [errorShow, setErrorShow] = useState(false);
    const [doctorList, setDoctorList] = useState([]);
    let accessToken = localStorage.getItem('accessToken');

    const navigate = useNavigate(); // Initialize useNavigate hook

    useEffect(() => {
        // Fetch the list of doctors
        fetch(base_url + '/doctors?limit=1000', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + accessToken,
            }
        })
            .then((response) => response.json())
            .then((data) => setDoctorList(data.doctors))
            .catch((error) => {
                console.error('Error fetching doctors:', error);
            });
    }, [accessToken, base_url]);

    useEffect(() => {
        if (handleClose) {
            setErrorShow(false);
        }
    }, [handleClose]);

    function generateRandomString(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    const randomString = generateRandomString(6);
    const schema = yup.object().shape({
        surname: yup.string().min(2, 'Минимум 2 буквы').required('Обязательное поле'),
        name: yup.string().min(2, 'Минимум 2 буквы').max(50, 'Максимум 50 букв').required('Обязательное поле'),
        IIN: yup.number().positive('Вводите только цифры').required('Обязательное поле').typeError("ИИН содержит только цифры").test('len', 'Должно быть 12 символов', val => val.toString().length === 12),
        hashed_password: yup.string().required('Заполните поле пароля'),
    });

    const sendFormData = async (data) => {
        let accessToken = localStorage.getItem('accessToken');
        try {
            const response = await fetch(base_url + '/patients', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                if (response.status === 400) {
                    const errorData = await response.json(); // Get the error details from the response body
                    throw new Error(`Bad Request: ${errorData.message}`);
                } else {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            }
            const result = await response.json();
            console.log('Form submitted successfully:', result);

            handleClose(); // Close the modal after successful submission
            navigate('/patient/' + result.id+'/patient'); // Replace with the appropriate route
        } catch (error) {
            console.error("Error sending form:", error);
            setErrorShow(true);
        }
    };

    return (
        <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Добавить пациента</Modal.Title>
            </Modal.Header>
            <Formik
                validationSchema={schema}
                onSubmit={(values) => {
                    sendFormData(values).then(r => console.log('Form data sent'));
                }}
                initialValues={{
                    surname: '',
                    name: '',
                    IIN: '',
                    patronymic: '',
                    hashed_password: randomString,
                    doctor_id: hideDoctorList ? doctorID : null
                }}
            >
                {({ handleSubmit, handleChange, values, touched, errors, handleBlur, setValues }) => {
                    return (
                        <Form noValidate onSubmit={handleSubmit}>
                            <Modal.Body>
                                <Alert variant="danger" show={errorShow} dismissible onClose={() => setErrorShow(false)}>
                                    <Alert.Heading>Ошибка добавления пациента</Alert.Heading>
                                    <p>
                                        Данный пациент существует в базе
                                    </p>
                                </Alert>
                                <Row className="mb-3">
                                    <Form.Group as={Col} controlId="surname">
                                        <Form.Label>Фамилия</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="surname"
                                            value={values.surname}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            isInvalid={touched.surname && errors.surname ? !!errors.surname : null}
                                            placeholder="Введите фамилию"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.surname}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="name">
                                        <Form.Label>Имя</Form.Label>
                                        <Form.Control type="text"
                                                      name="name"
                                                      value={values.name}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      isInvalid={touched.name && errors.name ? !!errors.name : null}
                                                      placeholder="Введите имя" />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="patronymic">
                                        <Form.Label>Отчество</Form.Label>
                                        <Form.Control type="text"
                                                      name="patronymic"
                                                      value={values.patronymic}
                                                      onChange={handleChange}
                                                      placeholder="Введите отчество при наличии" />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <Col xs="auto">
                                        <Form.Group className="mb-3" controlId="IIN">
                                            <Form.Label>ИИН пациента</Form.Label>
                                            <Form.Control
                                                placeholder="Введите ИИН цифрами"
                                                value={values.IIN}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.IIN && errors.IIN ? !!errors.IIN : null}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.IIN}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <hr />
                                <Row className="mb-3">
                                    <Col xs="auto">
                                        <Form.Group className="mb-3" controlId="hashed_password">
                                            <Form.Label>Установить пароль для пациента</Form.Label>
                                            <Form.Control
                                                placeholder="Введите пароль для пациента"
                                                value={values.hashed_password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                isInvalid={touched.hashed_password && errors.hashed_password ? !!errors.hashed_password : null}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {errors.hashed_password}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <hr />
                                <Row className="mb-3">
                                    <Col xs="auto">
                                        {!hideDoctorList && (
                                            <Form.Group className="mb-3" controlId="doctor_id">
                                                <Form.Label>Выберите доктора</Form.Label>
                                                <Form.Select
                                                    name="doctor_id"
                                                    value={values.doctor_id}
                                                    onChange={(e) => {
                                                        handleChange({
                                                            target: {
                                                                name: e.target.name,
                                                                value: Number(e.target.value), // Convert the selected value to a number
                                                            },
                                                        });
                                                    }}
                                                    onBlur={handleBlur}
                                                    isInvalid={touched.doctor_id && errors.doctor_id ? !!errors.doctor_id : null}
                                                >
                                                    <option value="">Выберите доктора из списка</option>
                                                    {Array.isArray(doctorList) && doctorList.map((doctor) => (
                                                        <option key={doctor.id} value={doctor.id}>
                                                            {doctor.surname} {doctor.name} {doctor.patronymic} - {doctor.qualification.name}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        )}
                                    </Col>
                                </Row>
                            </Modal.Body>
                            <Modal.Footer>
                                <Button variant="secondary" onClick={handleClose}>
                                    Отменить
                                </Button>
                                <Button variant="primary" type="submit" onClick={handleSubmit}>
                                    Сохранить
                                </Button>
                            </Modal.Footer>
                        </Form>
                    );
                }}
            </Formik>
        </Modal>
    );
}
export default AdminAddPatientMenu;