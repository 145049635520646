import React, {useContext, useState,useEffect} from "react";
import {Modal,Form,Button,Container,Row,Accordion,Col} from "react-bootstrap";
import AuthContext from "../../../../../auth/authContext";
import * as Yup from "yup";
import {ErrorMessage, Field, Formik} from "formik";
import {OnlineTestFields} from "../../fieldsPatientsData/onlineTestFields";
import Select from "react-select";
import {OnlineTestSelect} from "../../fieldsPatientsData/onlineTestSelect";
import {format} from "date-fns";


const DigitalTests = ({patientID}) => {
    const base_url = process.env.REACT_APP_BASE_URL;
    const [errorShow, setErrorShow] = useState(false);
    const [patientOnlineTest, setPatientOnlineTest] = useState(false);
    const [dataLoading, setDataLoading] = useState(true);
    const [show, setShow] = useState(false);
    const {refresh, setIsAuthenticated, redirectTo, setRedirectTo } = useContext(AuthContext);
    const [isLoggedOut, setIsLoggedOut] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [editData, setEditData] = useState(null);
    const currentUserData = JSON.parse(localStorage.getItem('currentUserData') || '{}');


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const translateKey = (key) => {
        const translation = OnlineTestFields.find((item) => item.label === key);
        return translation ? translation.name : key;
    };
    const translateArrayValues = (key, value) => {
        if (OnlineTestSelect[key]) {
            const option = OnlineTestSelect[key].find((item) => item.label === value);
            return option ? option.translatedLabel : value;
        }
        return value;
    };
    const handleLogout = () => {
        setIsAuthenticated(false);
        setRedirectTo('/login');
        localStorage.removeItem('currentUserData');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        setIsLoggedOut(true);
    };

    const fetchPatientDigitalTest = async () => {
        const currentUserData = localStorage.getItem('currentUserData');
        let accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');

        if (!currentUserData) {
            handleLogout();
            return;
        }

        if (!accessToken && refreshToken) {
            try {
                await refresh();
                accessToken = localStorage.getItem('accessToken');
            } catch (error) {
                handleLogout();
                return;
            }
        }

        try {
            let response = await fetch(base_url + `/online-tests/patient/${patientID}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + accessToken
                }
            });

            if (response.status === 401) {
                try {
                    await refresh();
                    accessToken = localStorage.getItem('accessToken');
                    response = await fetch(base_url + `/online-tests/patient/${patientID}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + accessToken
                        }
                    });
                } catch (error) {
                    handleLogout();
                    return;
                }
            }

            const responseOnlineTest = await response.json();
            setPatientOnlineTest(responseOnlineTest);
            setDataLoading(false);
        } catch (error) {
            console.log("An error occurred while trying to fetch the patient's data.");
            setDataLoading(false);
        }
    };

    useEffect(() => {
        if (patientID) {
        fetchPatientDigitalTest()
        }

    },[patientID]);


    const initialValues = OnlineTestFields.reduce((values, item) => {
        switch (item.type) {
            case 'select':
            case 'number':
                values[item.label] = ''; // Initialize select and number fields with an empty string
                break;

            default:
                values[item.label] = ''; // Default to empty string for any other type
        }
        return values;
    }, {});

    const validationSchema = Yup.object().shape({
        height: Yup.number().typeError('Height must be a number').min(0, 'Height must be positive'),
        drivesVehicle: Yup.boolean(),
    });
    const handleSubmit = (values) => {
        values['patient_id']=patientID;
        console.log('Form Data:', values);
        sendFormData(values);
    }
    if (dataLoading) {
        return <div className='loader'></div>;
    }
    const renderFormField = (item, formik) => {
        const { values, setFieldValue } = formik;
        switch (item.type) {
            case 'select':
                return (
                    <div key={item.label} className="form-group mb-2">
                        <label>{item.name}</label>
                        <Select
                            options={
                                OnlineTestSelect[item.label]?.map((option) => ({
                                    value: option.label, // Use `label` as the value
                                    label: option.translatedLabel, // Use `translatedLabel` as the displayed text
                                })) || []
                            }
                            value={
                                OnlineTestSelect[item.label]?.find(
                                    (option) => option.label === values[item.label]
                                ) && {
                                    value: values[item.label],
                                    label: OnlineTestSelect[item.label].find(
                                        (option) => option.label === values[item.label]
                                    )?.translatedLabel,
                                }
                            }
                            onChange={(option) =>
                                setFieldValue(item.label, option ? option.value : '')
                            }
                            placeholder="Выберите один из вариантов"
                        />
                        <ErrorMessage
                            name={item.label}
                            component="div"
                            className="text-danger"
                        />
                    </div>
                );
            case 'number':
                return (
                    <div key={item.label} className="form-group mb-2">
                        <label>{item.name}</label>
                        <Field
                            type="number"
                            name={item.label}
                            className="form-control"
                            placeholder="Введите число"
                            onWheel={(e) => e.target.blur()} // Prevent number change on wheel scroll
                        />
                        <ErrorMessage name={item.label} component="div" className="text-danger" />
                    </div>
                );
            default:
                return null;
        }
    };

    const sendFormData = async (data)=> {
        console.log('Sending form data...');
        const currentUserData = localStorage.getItem('currentUserData');
        let accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            const response = await fetch(base_url + '/online-tests/', {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + accessToken,
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                if (response.status === 400) {
                    const errorData = await response.json();
                    throw new Error(`Bad Request: ${errorData.message}`);
                } else {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            }

            const result = await response.json();
            console.log('Form submitted successfully:', result);
            handleClose();

        } catch (error) {
            console.error('Error sending form:', error);
            setErrorShow(true);
        }
    }
    if (dataLoading) {
        return <div className='loader'></div>;
    }



    const confirmDelete = async () => {
        console.log(`Confirm delete for patient with ID: ${deleteId}`);
        const currentUserData = localStorage.getItem('currentUserData');
        let accessToken = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        try {
            const response = await fetch(base_url+`/online-tests/${deleteId}/`, {
                method: 'DELETE',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + accessToken,
                },
            });
            if (response.ok) {
                console.log('Patient data deleted successfully');
                // Add logic to update the UI after successful deletion
            } else {
                console.error('Failed to delete patient data');
            }
        } catch (error) {
            console.error('An error occurred while deleting patient data:', error);
        }
        setShowDeleteModal(false);
        fetchPatientDigitalTest()
    };

    const openDeleteModal = (id) => {
        setDeleteId(id);
        setShowDeleteModal(true);
    };

    const DeleteModal = ({ show, onHide, confirmDelete }) => (
        <Modal show={show} onHide={onHide} backdrop="static" keyboard={false} size="sm" centered>
            <Modal.Header closeButton>
                <Modal.Title>Удалить тест электронный тест</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Вы уверены что хотите удалить электронный тест?</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>Отменить</Button>
                <Button variant="danger" onClick={confirmDelete}>Удалить</Button>
            </Modal.Footer>
        </Modal>
    );

    return (
        <Container className="p-4  rounded-3 border shadow-lg">
            <div className='d-flex mb-3  justify-content-between align-content-center'>
                <h4 className=' mt-2'>Список электронных тестов</h4>


            </div>
            <DeleteModal show={showDeleteModal} onHide={() => setShowDeleteModal(false)} confirmDelete={confirmDelete} />

            <div>
            <div className='overflow-x-auto'>
            {Array.isArray(patientOnlineTest) && patientOnlineTest.length > 0 ? (
               
                <table className='dynamic_table'>
                    <thead>
                    <tr>
                        {patientOnlineTest.map((dataInstance, index) => (
                            index < 1 && (
                            <>
                                <th className="asd"></th>
                                {Object.keys(dataInstance)
                                    .filter((key) => key !== 'patient_id' && key !== 'id' && key !== 'created_at' && key !== 'updated_at')
                                    .map((key) => (
                                        <th className="text-dark" key={key}>{translateKey(key)}</th>
                                ))}   
                           </>
                        )))}
                         </tr>
                        </thead>
                        <tbody>
                            {patientOnlineTest.map((dataInstance, index) => (
                                <tr key={index}>
                                    <td className="asd">{format(new Date(dataInstance['created_at']), 'dd/MM/yyyy')}</td>
                                    {Object.keys(dataInstance)
                                        .filter((key) => key !== 'patient_id' && key !== 'created_at' && key !== 'id' && key !== 'updated_at')
                                            .map((key) => (
                                                <td>{translateArrayValues(key, dataInstance[key])}</td>
                                        ))}               
                                </tr>
                            ))}
                    </tbody>
                </table>
               
            ):null}
            </div>

            
                <Row>
                    <Accordion defaultActiveKey="0" alwaysOpen>
                        {Array.isArray(patientOnlineTest) && patientOnlineTest.length > 0 ? (
                            patientOnlineTest.map((dataInstance, index) => (
                                <Accordion.Item eventKey={String(index)} key={index}>
                                    <Accordion.Header>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                            <span>Электронный тест: {translateArrayValues('test_type', dataInstance.test_type)}</span>
                                            <span className='mx-2'>{format(new Date(dataInstance.created_at), 'dd/MM/yyyy')}</span>
                                        </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                        <div key={dataInstance.id}
                                             className="d-flex align-items-center justify-content-between">
                                            <h4 className="mb-0">Данные пациента</h4>
                                            <div>
                                                {currentUserData.role !== 'patient' && (
                                                    <button className="btn btn-danger"
                                                        onClick={() => openDeleteModal(dataInstance.id)}>Удалить
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                        <hr/>
                                        <div>
                                            <Row>
                                                {Object.keys(dataInstance)
                                                    .filter((key) => key !== 'patient_id' && key !== 'id' && key !== 'created_at' && key !== 'updated_at')
                                                    .map((key) => (
                                                        <Col md={6} className="pb-3 mb-3 border-bottom" key={key}>
                                                            <strong className="text-dark">{translateKey(key)}: </strong>
                                                            <span className="d-block">
                                                                    {translateArrayValues(key, dataInstance[key])}
                                                              </span>
                                                        </Col>
                                                    ))}
                                            </Row>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            ))
                        ) : (
                            <p>Электронных тестов нет</p>
                        )}
                    </Accordion>

                </Row>
            </div>




        </Container>

    )
}
export default DigitalTests;