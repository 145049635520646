import './doctorHeader.scss';
import { Link, Navigate, useLocation } from "react-router-dom";
import LogOutIcon from './icons/logoutButtonIcon.png'
import React, { useContext, useEffect } from 'react';
import AuthContext from '../../../auth/authContext';
import PageContext from '../../../contexts/pageContext';
import AdminPageHeader from "../../adminComponents/adminHeader/adminPageHeader";
import { useState } from 'react';


const DoctorHeader = ({doctorID}) => {
  let location = useLocation();
  const { setIsAuthenticated, redirectTo, setRedirectTo, homelink} = useContext(AuthContext);
  const { currentPage, handlePageChange } = useContext(PageContext);

    const [key, setKey] = useState('home');

    

    const logOutButtonHandle = () => {
    const confirmation = window.confirm("Вы уверены, что хотите выйти из аккаунта?");
    if (!confirmation) {
        return;
    }
    
    setIsAuthenticated(false);
    setRedirectTo('/login');
    localStorage.removeItem('currentUserData');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  };

  useEffect(() => {
    if (redirectTo) {
        setRedirectTo(null);
    }
  }, [redirectTo, setRedirectTo]);

  if (redirectTo) {
    return <Navigate to={redirectTo} replace />;
  }

  return (
      <>
      <AdminPageHeader homelink={localStorage.getItem('homelink')}/>
      </>
  );
}
  
  export default DoctorHeader;
