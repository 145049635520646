import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import {useContext} from "react";
import AuthContext from "../../../auth/authContext";




const AdminPageHeader =({homelink})=>{
    const { setIsAuthenticated, setRedirectTo } = useContext(AuthContext);

    const logOutButtonHandle = () => {
        const confirmation = window.confirm("Вы уверены, что хотите выйти из аккаунта?");
        if (!confirmation) {
            return;
        }

        setIsAuthenticated(false);
        setRedirectTo('/login');
        localStorage.removeItem('currentUserData');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
    };

    return(
        <Navbar expand="lg" className="navbar-bg w-100 " >
            <Container >
                <Navbar.Brand href={homelink}>
                    <img
                        alt=""
                        src="/favicon.ico"
                        width="90"
                        height="50"
                        className="d-inline-block align-top"
                    />{' '}

                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                <Navbar.Collapse id="basic-navbar-nav"  className="justify-content-end" >
                    <Nav>
                        <NavDropdown title="Мой профиль" id="basic-nav-dropdown navbar-toggler-icon" className='fs-6 fw-bold'>

                            <NavDropdown.Item href="#action/3.4" onClick={() => logOutButtonHandle()}>
                                Выйти из аккаунта </NavDropdown.Item>

                        </NavDropdown>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
        );
}
export default AdminPageHeader;